/*! Flickity v2.2.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
    position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
    position: absolute;
    background-color: #C7D300;
    border: none;
    color: white;

    transition-duration: .2s;

    &:hover {
        background-color: rgba(#C7D300, .5);
        cursor: pointer;
        transition-duration: .2s;
    }
}

.flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
    opacity: 0.6;
}

.flickity-button:disabled {
    opacity: 0.3;
    cursor: auto;
    /* prevent disabled button from capturing pointer up event. #716 */
    pointer-events: none;
}

.flickity-button-icon {
    fill: currentColor;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
    top: 50%;
    width: 38px;
    height: 38px;
    /* vertically center */
    transform: translateY(-50%);
}

.flickity-prev-next-button.previous { left: 10px; padding-right: 0; }
.flickity-prev-next-button.next { right: 10px; padding-left: 0; }
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
    width: 19px;
    height: 38px;
}

/* ---- page dots ---- */

.flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: 25px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background-color: rgba(#FFFFFF, .75);
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 1px 2px 4px rgba(0,0,0,.25);
}

.flickity-page-dots .dot.is-selected {
    background-color: #281A0E;
}
