*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

body {
    color: $color-font;
    font-size: 16px;
}

img {
    max-width: 100%;
    vertical-align: middle;
}

iframe,
object,
embed {
    max-width: 100%;
    vertical-align: middle;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 1em 0;
}

:link,
:visited {
    color: inherit;
}

p {
    line-height: 1.5em;
    margin-bottom: 1.25em;
}

.cf::after {
    content: "";
    display: table;
    clear: both;
}

.is-visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}


body {
    font-family: $font-copy-fallback;
    font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-heading-fallback;
    font-weight: 400;
}

button {
    cursor: pointer;
}

a,
button,
input,
select,
textarea {
    &:focus {
        outline: dotted 1px black;
    }
}

textarea {
    resize: vertical;
}

.fonts-loaded {
    button,
    input,
    optgroup,
    select,
    textarea,
    body {
        font-family: $font-copy;
        -moz-osx-font-smoothing: grayscale;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $font-heading;
        -moz-osx-font-smoothing: grayscale;
        font-weight: 700;
    }

    .special-heading {
        margin: 0;
        font-family: $font-heading-special;
        font-size: 6.5em;
        font-size: calc(1em + 28vw);
        font-weight: 400;
        line-height: .78em;

        &::before {
            content: '\00a0'; // non breaking space. ensures that the first letter is not cut off
        }

        &--us {
            padding: 0 .25em;
            font-family: $font-heading-special-us;
            font-size: calc(1em + 5.26vw);
            line-height: 1em;
            letter-spacing: -0.02em;

            &::before {
                display: none;
            }
        }
    }
}

@media screen and (min-width: 600px) and (max-width: 1279px) {
    .fonts-loaded .special-heading {
        font-size: 8em;
            font-size: calc(1em + 12vw);

        &--us {
            font-size: calc(1em + 5.26vw);
        }
    }
}

@media screen and (min-width: 1280px) {
    .fonts-loaded .special-heading {
        font-size: 10em;

        &--us {
            font-size: 5.26em;
        }
    }
}

@media screen and (max-width: 900px) {
    .fonts-loaded .special-heading {
        line-height: 1em;
        font-size: calc(1em + 12vw);
        padding-bottom: 5px;
    }
}
