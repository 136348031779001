.carousel-header {
    position: relative;

    &__content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        text-align: center;
        color: white;
        }

    &__carousel {

        }
    }

.carousel-header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__title {
        text-shadow: 1px 1px 3px rgba(black, .3);
        }

    &__link {
        display: inline-block;
        padding: .7em .8em .58em;
        background-color: $color-secondary;
        text-transform: uppercase;
        font-size: .6em;
        align-self: center;

        &:link,
        &:visited {
            text-decoration: none;
            color: inherit;
            }

        & > * {
            display: inline-block;
            vertical-align: middle;
            margin-#{$left}: .5em;
            transition: .2s transform;
            }

        &:focus,
        &:hover {
            text-decoration: underline;
            }

        &:active > * {
            transform: translateX(50%);
            }
        }
    }

.carousel {

    &__item {
        padding-top: 61.5384615%;
        width: 100%;
        background-size: cover;
        background-position: center center;
        }

    //& > &__item + * {
    //     no js or something went wrong with flickity. hide all frames except the first
        //display: none;
        //}
    }

@media screen and (min-width: 651px)
{
    .carousel-header-content {

        &__link {
            padding: 1em 1em .78em;
            font-size: .875em;
            }
        }


    .carousel__item {
        padding-top: 41.40625%;
        }
}
