.custom-number-input {
    display: inline-block;
    width: 58px;
    height: 45px;
    border: solid 1px rgb(245, 241, 237);
    position: relative;

    &:focus-within {
        outline: dotted 1px #333;
        & :focus {
            outline: none;
            }
        }

    }


.custom-number-input__input {
    width: 36px;
    height: 100%;
    padding-#{$left}: .5em;
    border: 0;
    text-align: center;
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }
    }

.custom-number-input__spinner {
    width: 15px;
    height: 22px;
    border: 0;
    background-color: transparent;
    padding: 0;
    position:  absolute;
    #{$right}: 0;

    &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position:  absolute;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        }

    &--up {
        top: 0;

        &:hover::before {
            border-bottom-color:  darken($color-brown-gray, 10);
            }

        &:active::before {
            border-bottom-color:  darken($color-brown-gray, 20);
            }

        &::before {
            border-bottom: 10px solid $color-brown-gray;
            bottom: 2px;
            }
        }

    &--down {
        bottom: 0;

        &:hover::before {
            border-top-color:  darken($color-brown-gray, 10);
            }

        &:active::before {
            border-top-color:  darken($color-brown-gray, 20);
            }

        &::before {
            border-top: 10px solid $color-brown-gray;
            top: 3px;
            }
        }
    }
