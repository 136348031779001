.specs {
    $line-distance: 2.142857143em;
    $number-of-lines-breakpoint-map: (840px: 10,
                                      900px: 11,
                                      960px: 12,
                                      1020px: 13,
                                      1080px: 9,
                                      1280px: 11,
                                      1340px: 12,
                                      1400px: 13);

    margin: 0;
    position: relative;
    overflow: hidden;
    transition: .2s height ease-out;
    background-size: $line-distance $line-distance;
    background-image: linear-gradient(transparent           95%,
                                      $color-gray-dark 99%,
                                      $color-gray-dark);

    &--fixed-height {
        height: 9 * $line-distance;

        &::before {
            content: '';
            display: block;
            height: $line-distance;
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            // https://larsenwork.com/easing-gradients/#editor
            background-image: linear-gradient(to bottom,
                hsla(0, 0%, 100%, 0)    0%,
                hsla(0, 0%, 100%, .035) 9.1%,
                hsla(0, 0%, 100%, .08)  17.7%,
                hsla(0, 0%, 100%, .13)  26.1%,
                hsla(0, 0%, 100%, .183) 34.2%,
                hsla(0, 0%, 100%, .24)  42.1%,
                hsla(0, 0%, 100%, .301) 49.8%,
                hsla(0, 0%, 100%, .366) 57.2%,
                hsla(0, 0%, 100%, .433) 64.3%,
                hsla(0, 0%, 100%, .504) 71%,
                hsla(0, 0%, 100%, .579) 77.3%,
                hsla(0, 0%, 100%, .657) 83.1%,
                hsla(0, 0%, 100%, .74)  88.4%,
                hsla(0, 0%, 100%, .826) 93.1%,
                hsla(0, 0%, 100%, .916) 97.1%,
                hsl(0, 0%, 100%)        100%);
            }
        }

    & > * {
        line-height: $line-distance;
        }

    &::after {
        content: '';
        display: table;
        clear: both;
        }

    @each $px, $lines in $number-of-lines-breakpoint-map
    {
        @media screen and (min-width: $px)
        {
            &--fixed-height {
                height: $lines * $line-distance;
                }
        }
    }
    }

.specs__label {
    float: left;
    clear: both;
    }

.specs__value {
    margin: 0;
    float: right;
    }
