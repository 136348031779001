.sm-list {
    margin: 0;
    padding: 0;
    list-style: none;

    &:after {
        content: '';
        display: table;
        clear: $left;
        }

    &__item {
        float: $left;
        }

    &__item + &__item {
        margin-#{$left}: 15px;
        }
    }
