.filter-page {
    padding: 0;
    max-width: none;

    &__filters {
        margin: 0;
        }

    &__active-filters {
        margin: 30px;
        }
    }

.filter-group {
    padding: 0;
    margin: 0;
    border: 0;

    &__title {
        display: block;
        width: 100%;
        padding: 20px 0;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        user-select: none;
        font-size: .875em;

        .no-js & {
            cursor: default;
            user-select: initial;
            }
        }

    &__panel {

        }
    }

.filter-group-panel {
    &[aria-hidden=true] {
        display: none;
        }

    &__item {
        margin-bottom: 4px;
        }
    }


.filter-group-btn {
    padding: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    }



@media screen and (max-width: 599px)
{
    .filter-page {
        &__filter-btn {
            position: fixed;
            #{$right}: 20px;
            top: 20px;
            z-index: 2;
            }
        }

    .filters {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: calc(100% - #{$wide-header-height});
        max-width: 230px;
        position: fixed;
        top: $wide-header-height;
        #{$right}: 0;
        z-index: 2;
        background-color: white;
        overflow-y: auto;
        transition: .2s transform;
        border-top: 1px solid $color-gray;

        .no-animations & {
            transition-duration: 0s;
            }

        &[aria-hidden=true] {
            transform: translateX(if-ltr(100%, -100%));
            }
        &[aria-hidden=false] {
            transform: translateX(0);
            }

        &--hidden {
            display: none;
            }

        &__search {
            flex-grow: 1;
            }
        }

    .filter-group {
        width: 100%;
        padding: 0 20px;
        border-bottom: 1px solid $color-gray;

        &__btn {
            margin-#{$left}: .5em;
            transition: .2s transform;

            &[aria-expanded=true]{
                transform: rotate(-180deg);
                }

            }
        }

    .filter-search {
        width: 100%;
        padding: 0 20px 20px;
        border-bottom: solid 1px $color-gray;
        font-size: .875em;

        &__label {
            display: block;
            padding: 20px 0;
            font-weight: bold;
            text-transform: uppercase;
            cursor: pointer;
            user-select: none;
            }

        &__input {
            width: 100%;
            padding: .8em;
            border: solid 1px $color-gray;
            }
        }

    .filter-bottom {
        padding: 20px;
        }
}

@media screen and (min-width: 600px)
{
    .filter-page {
        &__filter-btn {
            display: none;
            }

        &__filters {
            display: flex;
            flex-wrap: wrap;
            padding: 30px 25px 0;

            & > :last-child {
                align-self: flex-start;
                }
            }
        }

    .filter-search {

        &__label {
            position: absolute;
            left: -100000px;
            top: -100000px;
            }

        &__input {
            padding: .4em;
            border: $color-highlight solid 2px;
            font-size: .875em;
            background: no-repeat url('/assets/images/search-icon-small.svg') calc(100% - 5px) center;
            }
        }


    .filter-group {
        min-height: 40px;
        flex-grow: 1;
        padding: 0 5px;
        }

    .filter-search {
        margin: 0 5px;
        }

    .filter-bottom {
        flex-grow: 1;
        text-align: right;
        margin-#{$right}: 5px;
        }

    .filter-group__title {
        font-weight: normal;
        background-color: white;
        color: $color-highlight;
        border: $color-highlight solid 2px;
        padding: 5px 10px;

        &--expanded {
            padding-bottom: 15px;
            position: relative;
            border-bottom: 0;

            &::after {
                content: '';
                display: block;
                background-color: white;
                width: 100%;
                height: 2px;
                position: absolute;
                bottom: -2px;
                left: 0;
                z-index: 2;
                }
            }
        }

    .filter-group__btn {

        }

    .filter-group__panel {
        margin-bottom: 20px;
        padding: 10px;
        border: $color-highlight solid 2px;
        border-top: 0;
        background-color: white;

        &[aria-hidden=false] {
            position: absolute;
            z-index: 1;
            border-top: $color-highlight solid 2px;
            margin-#{$right}: 2px;
            }
        }

    .filter-group-btn {
        width: 1em;
        height: 1em;

        &__icon {
            display: inline-block;
            width: 100%;
            height: 100%;
            position: relative;
            transition: .2s transform;
            transform-origin: center .6em;
            $arrow-thickness: 2px;
            $arrow-size: 7px;

            &::before,
            &::after {
                content: '';
                display: block;
                width: $arrow-size;
                height: $arrow-thickness;
                background-color: $color-highlight;
                position: absolute;
                top: 4px;
                left: 3px;
                transform-origin: top left;
                }

            &::before {
                transform: translate($arrow-thickness, $arrow-size) rotate(225deg) translateX(-$arrow-thickness);
                }

            &::after {
                transform: translate($arrow-thickness, $arrow-size) rotate(-45deg);
                }

            & > * {
                display: none;
                }
            }

        &[aria-expanded="true"] &__icon {
            transform: rotateX(-180deg);
            }
        }

    }


@media screen and (min-width: 800px)
{
    .filter-group {
        //padding: 0 20px;
        }


    .filter-group__panel {

        &[aria-hidden=false] {
            min-width: 240px;
            }
        }
}
