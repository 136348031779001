.btn {
    display: inline-flex;
    height: 42px;
    padding: .9em 1.5em;
    border: 0;
    border-radius: 0;
    background-color: $color-secondary;
    font-size: .875em;
    font-weight: 300;
    letter-spacing: .02em;
    text-transform: uppercase;
    cursor: pointer;
    align-items: center;

    &,
    &:link,
    &:visited{
        color: white;
        text-decoration: none;
        }

    &--small {
        height: 31px;
        padding: 0 1.3em 0 1em;
        }

    &:not(.btn--prev).btn--ghost {
        padding-#{$right}: 0;
        }

    &--clean {
        background: transparent;
        border: none;
        color: white;
        transition-duration: .2s;

        &:hover {
            color: darken(white, 20%);
            transition-duration: .2s;
        }
    }

    &--ghost.btn--prev {
        padding-#{$left}: 0;
        }


    &--ghost {
        background-color: transparent;

        &:link,
        &:visited {
            color: $color-primary;
            }

        path {
            fill: currentColor;
            }
        }

    &:not(&--prev) > * > * {
        position: relative;
        #{$right}: -7px;
        top: 1px;
        }

    &--prev > * > * {
        position: relative;
        #{$left}: -7px;
        top: 1px;
        @include if-ltr()
        {
            transform: rotate(180deg);
        }
        }

    @include if-rtl()
    {
        &:not(&--prev) > * > * {
            transform: rotate(180deg);
            }
    }

    &:hover &__text,
    &:focus &__text{
        text-decoration: underline;
        }

    &:not(&--prev):active > * > * {
        transition: $animation-duration transform;
        transform: translateX(50%);

        @include if-rtl()
        {
            transform: rotate(180deg) translateX(50%);
        }
        }

    &--prev:active > * > * {
        transition: $animation-duration transform;
        transform: rotate(180deg) translateX(50%);

        @include if-rtl()
        {
            transform: translateX(-50%);
        }
        }
    }
