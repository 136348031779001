.search-page {

    &__title {
        margin: 0 0 15px 0;
        float: $left;
        font-size: 1.75em;
        }

    &__number-of-results {
        margin: .68em 0 0 0;
        float: $right;
        font-weight: 600;
        font-size: .875em;
        }

    &__form {
        clear: both;
        }
    }

.searchform {

    &__input {
        display: block;
        width: 100%;
        height: 40px;
        padding: .5em;
        margin-bottom: 10px;
        border: 1px solid $color-form-border;
        border-radius: 0;
        background-clip: padding-box;
        &:focus {
            outline: 0;
            box-shadow: 0 0 3px 0 $color-form-border;
            border-bottom: 1px solid rgba(black, .23);
            }
        }

    &__submit {
        display: block;
        width: 100%;
        height: 40px;
        padding: 0 1.5em .2em;
        border: 0;
        border-radius: 0;
        background-color: $color-secondary;
        font-size: .875em;
        font-weight: 400;
        text-transform: uppercase;
        color: white;
        cursor: pointer;

        & > * {
            position: relative;
            top: 4px;
            margin-#{$left}: .5em;

            path {
                fill: white;
                }
            }
        }
    }

@media screen and (min-width: 600px)
{
    .search-page {

        &__inner-wrapper {
            max-width: 800px;
            margin: 0 auto;
            }

        &__title {
            margin: 0;
            font-size: 6.5em;
            font-size: calc(1em + 13vw);

            .fonts-loaded & {
                font-family: "otella", sans-serif;
                font-weight: 400;
                }
            }

        &__header {
            margin: -30px -30px 0 -30px;
            padding-top: 29%; // aspect ratio of the image
            position: relative;
            background: no-repeat center center / cover;

            & > * {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                text-shadow: rgba(black, .3) 1px 1px; // improve legibility
                color: white;
                }
            }

        &__number-of-results {
            margin-top: 1em;
            padding-bottom: 1em;
            font-size: 1.2em;
            border-bottom: 1px solid $color-form-border;
            }

        &__form {
            margin-top: 60px;
            }
        }

    @supports(display: flex)
    {
        .search-page {
            &__inner-wrapper {
                display: flex;
                flex-direction: column;
                & > * {
                    order: 2;
                    }
                }
            &__header {
                order: 0;
                }
            &__form {
                order: 1;

                & ~ * {
                    order: 2;
                    }
                }
            }

        .searchform {
            display: flex;

            &__input {
                width: auto;
                margin-bottom: 0;
                flex-grow: 1;
                }

            &__submit {
                width: auto;
                white-space: nowrap;
                }
            }
    }
}


.search-results {
    clear: right;

    margin: 30px 0 0 0;
    padding: 0;
    list-style: none;

    &__item {
        margin: 0;


        }
    }


.search-result {
    padding-bottom: 25px;
    margin-bottom: 30px;
    border-bottom: 1px solid $color-form-border;

    &__header {
        display: flex;
        margin-bottom: 5px;
        flex-direction: row-reverse;
        align-items: start;
        justify-content: flex-end;
        }

    &__title {
        margin: 0;
        position: relative;
        top: -.20em;
        text-transform: capitalize;

        .fonts-loaded & {
            font-size: 1.25em;
            }
        }

    &__type {
        display: inline-block;
        margin-#{$right}: .8em;
        padding: .4em .8em .2em .8em;
        font-weight: 300;
        font-size: .875em;
        text-transform: capitalize;
        background-color: $color-primary;
        color: white;
        }

    &__url {
        display: block;
        margin-bottom: 10px;
        text-decoration: none;
        color: $color-highlight;
        font-size: .875em;
        }

    &__text {
        font-size: .875em;
        margin: 0;
        }
    }



@media screen and (min-width: 1440px)
{
    .search-page__title {
        font-size: 12.656em;
        }


    .fonts-loaded .search-result__title {
        font-size: 1.55em;
        top: -.1em;
        }
}
