.recipe-list {
    margin: 0;
    padding: 0;
    list-style: none;

    &__item {
        width: 50%;
        padding: 0;
        float: left;
        box-shadow: 0 0 4px -2px rgba(black, .4);
        }

    &__item-inner {
        padding-top: 100%;
        position: relative;
        }
    }

.recipe-list__item-inner.recipe-list__item-inner > * {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    }

@media screen and (min-width: 600px)
{
    .recipe-list__item {
        width: calc(100% / 3);
        }
}

@media screen and (min-width: 901px)
{
    .recipe-list__item {
        width: 25%;
        }
}
