.downloads-list {
    max-width: 480px;
    margin: 0;
    padding: 0;
    list-style: none;

    &__item {

        }
    }

.download-item {
    margin-bottom: 30px;
    position: relative;
    font-size: .875em;

    &:last-child {
        margin-bottom: 0;
        }

    &::after {
        content: '';
        display: table;
        clear: $left;
        }

    &__title {
        display: inline-block;
        width: 100%;
        max-width: calc(100% - 95px);
        margin-bottom: .5em;
        font-size: inherit;

        color: $color-highlight;
        }

    &__image {
        float: $left;
        margin-#{$right}: 20px;
        box-shadow: 0 2px 4px 0 rgba(black, .5);
        }

    &__text {

        }

    &__link {
        margin: 0;
        position: absolute;
        bottom: 0;
        font-size: .86em;
        }

    &__content {
        display: inline-block;
        max-width: calc(100% - 95px);
        padding-bottom: 40px;
        }
    }

@media screen and (max-width: 399px)
{
    .download-item {
        &__link {
            font-size: .86em;
            }
        &__image {
            max-height: 100px;
            }
        }
}

@media screen and (min-width: 400px)
{
    .download-item {
        &__title {
            max-width: calc(100% - 130px);
            }
        &__content {
            max-width: calc(100% - 130px);
            }
        &__link {
            font-size: 1em;
            }
        }
}

@media screen and (max-width: 449px)
{
    .download-list-container__image {
        max-width: calc(100% + 40px);
        margin: 20px -20px;
        }
}

@media screen and (max-width: 699px)
{
    .download-list-container {
        display: flex;
        flex-direction: column;
        & > * {
            order: 1;
            }
        &__image {
            order: 0;
            }
        }
}

@media screen and (min-width: 450px)
{
    .download-item {
        &__title {
            max-width: calc(100% - 150px);
            }
        &__content {
            max-width: calc(100% - 150px);
            }
        &__image {
            margin-#{$right}: 30px;
            }
        }
}

@media screen and (min-width: 450px) and (max-width: 699px)
{
    .download-list-container__image {
        max-width: calc(100% + 60px);
        margin: 30px -30px;
        text-align: center;
        background: $color-gray-light url('/assets/images/grain.png');
        }
}


@media screen and (min-width: 700px)
{
    .download-page__text {
        width: 50%;
        }

    .download-list-container {
        margin-bottom: 60px;
        position: relative;
        clear: $right;

        &__title {
            width: calc(50% + 10px);
            margin-#{$left}: -10px;
            padding: side-values(.2em 0 .2em 10px);
            float: $left;
            position: sticky;
            z-index: 1;
            top: $wide-header-height;
            background-color: rgba(white, .9);
            box-shadow: 0 0 1px 0 rgba(white, .9);
            -webkit-backdrop-filter: blur(2px);
            }
        &__image {
            float: $right;
            margin-#{$right}: -30px;
            position: sticky;
            top: $wide-header-height;
            width: 50%;
            }
        &__list {
            width: 50%;
            clear: $left;
            }
        }
    .download-item__link {
        font-size: .86em;
        }
}

@media screen and (min-width: 800px)
{
    .download-item__link {
        font-size: 1em;
        }
}

@media screen and (min-width: 1280px)
{
    .download-list-container{
        &__image {
            top: 0;
            }
        &__title {
            top: 0;
            }
        }
}
