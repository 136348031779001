@media screen and (max-width: 499px)
{
    .orderhistory-order {
        display: block;

        & > *:not(:last-child) {
            display: flex;
            margin-bottom: 3px;
            &::before {
                content: attr(data-label);
                flex-grow: 1;
                }
            }

        & > :first-child {
            margin-bottom: 8px;
            padding-bottom: 7px;
            border-bottom: 1px solid $color-brown-gray-light;
            }

        & > :last-child {
            display: block;
            width: 100%;
            margin-top: $grid-gutter-half;
            margin-bottom: $grid-gutter;
            padding-bottom: $grid-gutter-half;
            border-bottom: 1px solid $color-primary;
            }
        }
}

@media screen and (min-width: 500px)
{
    .orderhistory-order {

        & > * {
            padding: $grid-gutter-half 0;
            border-bottom: 1px solid $color-brown-gray-light;
            }

        &__date {
            width: 20%;
            }

        &__time {
            width: 15%;
            }

        &__count {
            width: 35%;
            }

        &__details {
            width: 30%;
            text-align: right;
            }
        }
}
