@media screen and (min-width: 600px)
{
    .login-page {
        &__inner-wrapper {
            max-width: 800px;
            margin: 0 auto;
            }

        &__form {
            margin-top: 60px;
            clear: both;
            }
        }
}
