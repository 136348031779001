.recipe-page__top {
  background-color: $color-primary;
  color: white;
}

.recipe-page-header {
  display: flex;
  padding: 30px 30px 0 30px;
  flex-direction: column;

  &__text {
    font-size: .875em;
    max-width: 38em;
  }

  & > * {
    order: 1;
  }

  &__breadcrumbs {
    order: 0;
  }
}

.recipe-page-contentarea__title {
  padding: 1em 30px;
  margin: 0;
}

.recipe-page-contentarea__title--hr {
  margin-bottom: 15px;
  background: $color-primary-dark url('/assets/images/grain-soft.png');
  color: white;
  font-size: 1.1em;
}

.recipe-page-contentarea__text {
  max-width: 38em;
}

.recipe-page-contentarea__half {
  padding: 0 30px;
  font-size: .875em;
}

.recipe-page-contentarea__subtitle {
  font-size: inherit;
}

.recipe-page__download {

}

@media screen and (max-width: 799px) {
  .recipe-page__top {
    display: flex;
    flex-direction: column;

    & > * {
      order: 1;
    }

    & > :last-child {
      order: 0;
    }
  }

  .recipe-page__image {
    background: $color-gray-light url('/assets/images/grain.png');
    text-align: center;
  }

  .recipe-page-contentarea__half + * {
    margin-top: 2em;
  }
}

@media screen and (min-width: 600px) {
  .recipe-page__image > * {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 800px) {
  .recipe-page__image > * {
    margin-bottom: 0;
  }

  .recipe-page__top {
    &::after {
      content: '';
      display: table;
      clear: $left;
    }

    & > * {
      float: $left;
      width: 50%;
    }
  }

  .recipe-page__download {
    float: $right;
  }

  .recipe-page-contentarea__title {
    margin-bottom: 30px;
    background: $color-primary-dark url('/assets/images/grain-soft.png');
    color: white;
  }

  .recipe-page-contentarea__subtitle {
    display: inline-block;
    width: 100%;
    max-width: calc(100% - 250px);
  }

  .recipe-page-contentarea {
    &::after {
      content: '';
      display: table;
      clear: $left;
    }

    &__half {
      width: 50%;
      float: $left;
    }
  }

  .recipe-page-contentarea__text {
    clear: $right;
  }
}