.page {
    background-color: white;
    padding: $grid-gutter-small;

    &__title {
        font-size: 1.5em;

        &--xl {
            font-size: 2em;
            }
        }

    &__text {
        font-size: .875em;

        &--constrained {
            max-width: 480px;
            }
        }

    &__breadcrumbs {
        margin-bottom: 30px;
        }

    &__header-title {
        margin: 0 0 15px 0;
        font-size: 1.75em;
        }

    &__header-link {
        display: inline-block;
        padding: .7em .8em .58em;
        background-color: $color-secondary;
        text-transform: uppercase;
        font-size: .6em;
        align-self: center;

        &:link,
        &:visited {
            text-decoration: none;
            color: inherit;
            }

        & > * {
            display: inline-block;
            vertical-align: middle;
            margin-#{$left}: .5em;
            transition: .2s transform;
            }

        &:focus,
        &:hover {
            text-decoration: underline;
            }

        &:active > * {
            transform: translateX(50%);
            }
        }
    }

.page-splitted {
    padding: 0;

    &__half {
        padding: $grid-gutter-small;

        &--not-padded {
            padding: 0;
            }
        }
    }

.page__centered-image {
    text-align: center;
    }


@media screen and (max-width: 599px)
{
    .page {
        &__header-title {
            margin-bottom: 1em;
            text-align: left;
            }
        &__header {
            background: none!important; // needed, because this style is declared inline in the HTML
            }
        }
}

@media screen and (min-width: 450px)
{
    .page,
    .page-splitted__half{
        padding: $grid-gutter;
        }

    .page-splitted,
    .page-splitted__half--not-padded {
        padding: 0;
        }
}

@media screen and (min-width: 600px)
{
    .page {
        &__header-title {
            margin: 0;
            float: $left;
            font-size: 6.5em;
            font-size: calc(1em + 13vw);

            .fonts-loaded & {
                font-family: "Birthstone", sans-serif;
                font-weight: 400;
                }
            }

        &__header-link {
            padding: 1em 1em .78em;
            font-size: .875em;
            }

        &__header {
            margin: -30px -30px 0 -30px;
            padding-top: 29%; // aspect ratio of the image
            position: relative;
            background: no-repeat center center / cover;

            & > * {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                text-shadow: rgba(black, .3) 1px 1px; // improve legibility
                color: white;
                }
            }
    }
}

@media screen and (min-width: 800px)
{
    .page-splitted {
        display: flex;

        &::after {
            content: '';
            display: table;
            clear: $left;
            }

        &__half {
            float: $left;
            width: 50%;
            }
        }
}

@media screen and (min-width: 1080px)
{
    .page {
        width: 100%;
        max-width: 1080px;
        margin: 0 auto;
        align-self: center;
        box-shadow: 0 0 2px 0 rgba(black, .1);
        }
}

@media screen and (min-width: 1280px)
{
    .page {
        margin: 0;
        box-shadow: if-ltr(30px, -30px) 0 15px -25px rgba(black, .1) inset;
        }
}

@media screen and (min-width: 1440px)
{
    .page__header-title {
        font-size: 8em;
        }
}
