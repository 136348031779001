
@media screen and (min-width: 530px)
{
    .splitted-half {
        display: flex;

        &__half {
            width: 50%;
            &:first-child {
                padding-#{$right}: 15px;
                }
            &:last-child {
                padding-#{$left}: 15px;
                }
            }
        }
}

.country-group {
    max-width: 470px;
    margin: 1em 0 3em 0;

    &__title {
        font-size: 1.5em;
        .fonts-loaded & {
            font-weight: 300;
            }
        }

    }


.language-list,
.country-group__countries {
    margin: 0;
    padding: 0;
    list-style: none;
    }

.language-group {
    margin-bottom: 1em;
    }

.language-group-header {
    display: flex;
    align-items: center;
    margin: .5em 0;
    //cursor: pointer;
    user-select: none;


    &::after {
        content: '';
        display: table;
        clear: both;
        }

    &__title {
        display: inline-block;
        margin: 0;
        font-size: .875em;
        vertical-align: middle;
        order: 1;
        flex-grow: 1;

        //:hover > & {
        //    text-decoration: underline;
        //    }
        }

    .fonts-loaded &__title {
        font-weight: 300;
        }

    &__flag {
        float: left;
        margin-#{$right}: 15px;
        order: 0;
        filter: drop-shadow(0 0 3px rgba(black, .2));
        transition: .2s 0s transform;
        //:hover > & {
        //    transform: scale(1.1);
        //    }
        }

    &__btn {
        display: none;
        float: right;
        order: 2;
        margin-#{$left}: 15px;
        }
    }

.language-group-header__btn {
    padding: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    transition: .2s 0s transform;

    &[aria-expanded=true] {
        transform: rotate(-180deg);
        }

    &[aria-expanded=false] {
        transform: rotate(0);
        }
    }


.language-group__body {
    padding-#{$left}: 39px;

    &[aria-hidden=true] {
        display: none;
        }

    &[aria-hidden=false] {
        display: block;
        }
    }

.language-list {

    &__item {
        font-size: .875em;
        margin-bottom: 1em;
        }

    &__link {
        display: inline-block;
        width: 100%;
        user-select: none;

        &:link,
        &:visited {
            color: $color-highlight;
            text-decoration: none;
            &:hover,
            &:focus {
                text-decoration: underline;
                }
            }
        }
    }

