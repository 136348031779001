.sidebar-search {
    border-bottom: solid 1px $color-gray;

    &:after {
        content: '';
        display: table;
        clear: $left;
        }
    }

.sidebar-search__field-wrapper {
    height: 43px;
    width: calc(100% - 48px);
    float: $left;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        border-top: 1px solid $color-gray;
        width: calc(100% - 40px);
        #{$left}: 20px;
        top: calc(1em + 15px);
        }
    }

.sidebar-search__field {
    border: 0;
    width: 100%;
    height: 100%;
    padding: side-values(0 0 0 1.428571429em);
    background-color: transparent;
    font-size: .875em;
    font-weight: 700;
    letter-spacing: .08em;

    &:focus {
        outline: none;
        }

    &::placeholder{
        color: $color-font-placeholder;
        }

    &:focus::placeholder{
        color: darken($color-font-placeholder, 15%);
        }
    }

.sidebar-search__submit {
    float: $left;
    }
