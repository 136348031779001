.recipe-page {
  padding: 0;

  &.old {
    @import 'recipe-page-old';
  }

  &.new {
    @import 'recipe-page-new';
  }
}