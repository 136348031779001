@keyframes menu-button__cross-top
{
    0%   { transform: translateY(-8px) rotate(0deg);    }
    50%  { transform: translateY(0)    rotate(0deg);    }
    100% { transform: translateY(0)    rotate(45deg);   }
}

@keyframes menu-button__cross-bottom
{
    0%   { transform: translateY(4px)  rotate(0deg);    }
    50%  { transform: translateY(-4px) rotate(0deg);    }
    100% { transform: translateY(-4px) rotate(-45deg);  }
}

//@keyframes menu-button__cross-middle
//{
//    0% {
//        background-color: $color-primary;
//        }
//    50%,
//    100% {
//        background-color: transparent;
//        }
//}

.menu-button {
    border: 0;
    padding: 0;
    height: 22px;
    border-radius: 0;
    background-color: transparent;
    text-transform: uppercase;
    cursor: pointer;

    &:focus {
        outline: dotted 1px black;
        }
    }

.menu-button__icon {
    display: inline-block;
    height: 4px;
    width: 26px;
    position: relative;
    vertical-align: middle;
    //animation: menu-button__cross-middle $animation-duration backwards;
    background-color: $color-primary;

    &::after,
    &::before {
        content: '';
        display: block;
        height: inherit;
        width: inherit;
        position: inherit;
        top: 0;
        left: 0;
        background-color: $color-primary;
        transform-origin: center center;
        }

    &::after {
        transform: translateY(4px);
        animation: reverse menu-button__cross-top $animation-duration backwards;
        }

    &::before {
        transform: translateY(-8px);
        animation: reverse menu-button__cross-bottom $animation-duration backwards;
        }
    }

.menu-button__text {
    display: inline-block;
    height: .9em;
    overflow: hidden;
    text-transform: uppercase;
    text-align: $left;
    & > * {
        display: block;
        height: 100%;
        transition: margin-top .2s;
        }
    }


[aria-expanded="true"] {
    & > .menu-button__icon {
        //animation: menu-button__cross-middle $animation-duration forwards;
        background-color: transparent;
        }

    & > .menu-button__icon::before {
        animation: menu-button__cross-top $animation-duration forwards;
        }

    & > .menu-button__icon::after {
        animation: menu-button__cross-bottom $animation-duration forwards;
        }
    & > .menu-button__text :first-child {
        margin-top: -.9em;
        }
    }

.no-animations {
    .menu-button__icon,
    .menu-button__icon::before,
    .menu-button__icon::after {
        animation-duration: 0s !important;
        }
    }

@media screen and (max-width: 370px)
{
    .menu-button__text {
        display: none;
        }
}
