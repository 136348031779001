.contact-location-list {
    padding: 0;
    margin: 0;
    list-style: none;
    box-shadow: 0 0 1px 0 rgba(black, .1) inset;
    }

.contact-location-list__item {

    }


.contact-location {
    color: white;
    background-color: $color-primary;
    font-size: .875em;

    &__title {
        padding: 20px 20px 0 20px;
        margin: 0;
        text-transform: uppercase;
        font-weight: 400;
        }

    &__subtitle {
        padding: 0 20px;
        font-weight: 300;
        }

    &__img {

        }

    &__address {
        padding: 0 20px;
        font-weight: 300;
        }

    &__contact {
        padding: 0 20px;
        font-weight: 300;
        color: $color-highlight;

        & > * {
            display: block;
            }

        }
    }

.contact-location-img {
    position: relative;

    &__img {
        width: 100%;
        max-width: none;
        }

    &__flag {
        position: absolute;
        top: 10px;
        width: 33px;
        #{$right}: 10px;
        }
    }


@media screen and (max-width: 470px)
{
    .contact-location {
        display: flex;
        flex-direction: column;

        & > * {
            order: 1;
            }

        &__img {
            order: 0;
            }
        }
}

@media screen and (min-width: 360px) and (max-width: 470px)
{
    .contact-location-list {
        padding: 30px;
        margin: 0 auto;
        background: $color-gray-light url('/assets/images/grain.png');

        & > * {
            max-width: 360px;
            margin: 0 auto;
            }
        }
}

@media screen and (min-width: 471px)
{
    .contact-location {
        max-width: 540px;

        & > * {
            max-width: 50%;
            width: 100%;
            display: inline-block;
            }

        &::after {
            content: '';
            display: table;
            clear: both;
            }

        &__img {
            width: 50%;
            }
        }

    :nth-child(odd) > .contact-location__img {
        float: $left;
        }

    :nth-child(even) > .contact-location__img {
        float: $right;
        }

}

@media screen and (min-width: 540px) and (max-width: 799px)
{
    .contact-location-list {
        padding: 30px;
        margin: 0 auto;
        background: $color-gray-light url('/assets/images/grain.png');

        & > * {
            max-width: 540px;
            margin: 0 auto;
            }
        }
}

@media screen and (min-width: 800px) and (max-width: 920px)
{
    .contact-location {
        font-size: .7em;
        }
}


@media screen and (min-width: 1080px)
{
    .contact-location-list {
        float: $right;
        max-width: 540px;
        }
}
