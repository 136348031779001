
.blockpage--iframe {
    background-color: white;
    }

.blockpage__header {
    position: relative;

    & > * {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-shadow: rgba(black, .3) 1px 1px; // improve legibility
        color: white;
        }

    &::before {
        content: '';
        display: block;
        padding-top: 50%; // aspect ratio of the image
        background-size: cover;
        }

    &--about::before {
        background-image: url('/assets/images/about-us/about-us-600x300.jpg');
        }

    &--service::before {
        background-image: url('/assets/images/service/service-600x300.jpg');
        }

    &--iframe::before {
        background-image: url('/assets/images/about-us/iframe-600x300.jpg?1');
        }
    }


.blockpage-splitblock {
    min-height: 270px;

    &__content {
        background: $color-primary url('/assets/images/grain-soft.png');
        color: white;
        padding: 30px;
        font-size: .875em;

        & > * {
            max-width: 400px;
            }

        &--negative {
            background: $color-gray-light url('/assets/images/grain.png');
            color: $color-font-dark;
            }
        }

    &::after {
        content: '';
        display: block;
        width: 100%;
        min-height: inherit;
        background-image: inherit;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        }

    &--sandwiched {
        &::after {
            display: none;
            }
        & > :last-child {
            min-height: inherit;
            background-size: cover;
            background-position: center center;
            }
        }
    }

@media screen and (min-width: 600px)
{
    .blockpage__header {

        &::before {
            padding-top: 29%; // aspect ratio of the image
            }

        &--about::before {
            background-image: url('/assets/images/about-us/about-us-1280x371.jpg');
            }

        &--service::before {
            background-image: url('/assets/images/service/service-1280x371.jpg');
            }
        &--iframe::before {
            background-image: url('/assets/images/about-us/iframe-1280x371.jpg?1');
            }
        }

    .blockpage-splitblock {
        position: relative;
        padding-#{$right}: 50%;

        &__content {
            display: flex;
            min-height: inherit;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            }

        &--flipped{
            padding-#{$left}: 50%;
            padding-#{$right}: 0;
            }

        &::after {
            width: 50%;
            position: absolute;
            top: 0;
            #{$right}: 0;
            bottom: 0;
            #{$left}: auto;
            }

        &--flipped::after {
            #{$right}: auto;
            #{$left}: 0;
            }

        &--sandwiched {
            padding-#{$right}: 50%;

            & > :first-child,
            &::after {
                display: none;
                }

            & > :first-child,
            & > :last-child {
                width: 50%;
                position: absolute;
                top: 0;
                bottom: 0;
                }
            & > :last-child {
                #{$right}: 0;
                }
            }
        }
}

@media screen and (min-width: 1000px)
{
    .blockpage-splitblock {
        &--sandwiched {
            padding: 0 25%;

            & > :first-child,
            & > :last-child {
                display: block;
                width: 25%;
                }
            & > :first-child {
                #{$left}: 0;
                }
            }
        }
}
