.sm-btn {
    display: block;
    width: 28px;
    height: 28px;
    position: relative;
    background-color: $color-highlight;
    transition: .2s 0s background-color;

    &:hover,
    &:focus {
        background-color: darken($color-highlight, 15%);
        }
    }

.sm-btn::before {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    top: 6px;
    #{$left}: 6px;
    background: url('/assets/images/social-media-icons.svg') no-repeat 0 0;
    }

.sm-btn--facebook::before {
    background-position: 0 0;
    }

.sm-btn--linkedin::before {
    background-position: -16px 0;
    }

.sm-btn--youtube::before {
    background-position: -32px 0;
    }

.sm-btn--pinterest::before {
    background-position: -48px 0;
    }

.sm-btn--instagram::before {
    background-position: -64px 0;
    }

.sm-btn--we-chat::before {
    background-position: -80px 0;
    }

@media screen and (min-width: 768px)
{
    .sm-btn--we-chat {
        &::after {
            content: '';
            display: none;
            width: 129px;
            height: 129px;
            position: absolute;
            bottom: 100%;
            left: calc(-129px / 2 + 50%);
            background: no-repeat url('/assets/images/we-chat-qrcode.png');
            box-shadow: 0 0 2px 2px rgba(black, .1);
            }

        &:hover,
        &:focus {
            position: relative;
            cursor: help;
            background-color: $color-highlight;

            &::after {
                display: block;
                }
            }
        }
}

