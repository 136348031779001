.product-page {
  padding: 0;

  &__title {
    margin-bottom: .5em;
    font-size: 2em;
  }

  &__sub-title {
    padding: 1em 30px;
    margin: 0;
    background: $color-primary-dark url('/assets/images/grain-soft.png');
    color: white;
    font-size: 1.1em;
  }

  &__image {
    text-align: center;
  }

  &__procedure {
    clear: both;
  }
}

.product-page__top {
    display: flex;
    flex-direction: column-reverse;
    }

.product-page-header {
    padding: 30px 30px 0 30px;

    &__specs {
        font-size: .875em;
        }
    }

.product-page__description {
    font-size: .875em;
}

.product-page-header__info {
    width: auto;
    padding: 20px 30px;
    margin-#{$right}: -30px;
    margin-#{$left}: -30px;
    background: $color-product-info url('/assets/images/grain-soft.png');
    color: white;
    &::after {
        content: '';
        display: table;
        clear: left;
        }
    }

.product-page-header-info {
    position: relative;
    & > * {
        position: relative;
        }
    }

.product-page-header-info__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: bottom right;
    }

.product-page-content {

    & .product-page-carousel__item {
        padding-top: 100%;
        width: 100%;
        background-size: cover;
        background-position: center center;
    }
}

.product-page-order {
    padding-#{$right}: 15px;

    &__title {
        //margin-bottom: 1.5em;
        }

    &__price-from {
        margin-bottom: .3em;
        }

    &__price-to {
        font-size: 1.5em;
        }

    &__btn {
        margin-top: 15px;
        font-size: .9em;
        }

    &__href {
        background: none;
        color:white;
        border: 0;
        display: block;
        margin-top: 15px;
        font-size: .9em;
        font-weight: bold;
        padding: 0;
        text-decoration: underline;
        text-align: left;

        &:hover{
            color: $color-secondary;
        }
    }

    &__price-from,
    &__price-to {
        & > * {
            text-decoration: none;
            font-weight: bold;
            }
        }

    &__info {
        font-size: 0.79em;
        }
    &__piece-price {
        font-size: 0.6em;
        margin-top: 12px;
        display: inline-block;
        }
    &__quantity{
        display: block;
        width:50%;
        padding:10px 0;
        }
    }

.product-page-order-quantity{

    &__label{
        display: block;
        margin-bottom: .5em;
        font-size: .875em;
        font-weight: bold;
        }
    &__input{
        display: block;
        width: 100%;
        height: 48px;
        padding: .5em;
        border: 1px solid $color-form-border;
        border-radius: 0;
        background-clip: padding-box;

        &:focus {
            outline: 0;
            box-shadow: 0 0 3px 0 $color-form-border;
            border-bottom: 1px solid rgba(black, .23);
            }
        }
    }

.product-page-more-info {

    &__title {
        margin-bottom: .4em;
        }

    &__text {
        margin-bottom: .7em;
        font-size: .875em;
        }

    &__highlight {
        display: block;
        font-weight: bold;
        }

    &__link {
        display: block;
        float: $left;
        clear: $left;
        padding: .57em 1.17em;
        border: 2px solid white;
        box-shadow: 0 0 2px 0 rgba(black, .2),
                    0 0 2px 0 rgba(black, .2) inset;
        font-weight: bold;
        font-size: .875em;

        &:first-of-type {
            margin-bottom: 5px;
            }

        &:link,
        &:visited {
            text-decoration: none;
            }
        }
    }


.product-page-header-specs-buttons{
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row;
    justify-content: left;

    & > &__button{
        padding:0;
        margin:10px 0 20px;
        font-weight: bold;
        cursor: pointer;

        &:hover{
            text-decoration: underline;
        }
    }

    & > &__button:first-child{
        text-align: left;

        & > *:first-child{
            display: inline-block;
            transform: rotate(90deg);
            margin-right: 5px;
        }
    }

    & > &__button:last-child{
        text-align: right;
    }

}

@media screen and (max-width: 380px)
{
    .product-page-header__specs {
        width: auto;
        margin: 0 -30px;
        padding: 0 15px;
        font-size: .8em;
        }
}

@media screen and (max-width: 539px)
{
    .product-page-order {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid white;
        }
}

@media screen and (min-width: 300px)
{
    .product-page-order__btn {
        font-size: 1em;
        }
}

@media screen and (min-width: 540px)
{
    .product-page-order,
    * + .product-page-more-info {
        width: 50%;
        float: left;
        }

    * + .product-page-more-info {
        padding-#{$left}: 15px;
        }
}

@media screen and (max-width: 799.8px)
{
    .product-page__description {
        margin-bottom: $grid-gutter;
        }
}

@media screen and (min-width: 800px)
{
    .product-page__image > * {
        margin-bottom: 0;
        }

    .product-page__top {
        flex-direction: row-reverse;
        &::after {
            content: '';
            display: table;
            clear: $left;
            }

        & > * {
            float: $left;
            width: 50%;
            }
        }
}

@media screen and (min-width: 800px)  and (max-width: 1080px),
       screen and (min-width: 1280px) and (max-width: 1430px)
{
    .product-page-header {
        display: flex;
        flex-direction: column;

        &__info {
            margin-#{$left}: calc(-30px + -50vw)
            }

        &__specs {
            flex-grow: 1;
            }
        }

        .product-page__image {
            padding-bottom: 215px;
        }
}

@media screen and (min-width: 1280px) and (max-width: 1430px)
{
    .product-page-header__info {
        margin-#{$left}: calc(-30px + -50vw + #{$sidebar-width / 2});
        }
}

@media screen and (min-width: 1280px)
{
    .product-page__top > :last-child {
        padding-left: 18px;
        }
}
