.read-more-link {
    font-family: $font-copy;
    font-weight: bold;

    text-transform: uppercase;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    & > * {
        margin-#{$left}: .5em;
        transition: .2s 0s transform;
        display: inline-block;
        & > * {
            width: .6em; // 8.4
            height: 1.114285714em; // 15.6
            position: relative;
            top: .1em;
            }
        path {
            fill: currentColor;
            }
        }

    &,
    &:link,
    &:visited {
        color: inherit;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
            outline: 0;
            & > * {
                transform: translate(50%, 0);
                }
            }
        }
    }
