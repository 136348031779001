.related-product {
    display: inline-block;
    position: relative;
    text-align: center;
    font-size:  .875em;
    max-width: 100%;

    &:link,
    &:visited {
        text-decoration: none;
        color: inherit;
        }

    &:hover,
    &:focus {
        text-decoration: underline;
        }
    }

.related-product__img {
    transform: scale(.95);
    transition: .8s 0s transform;
    }

:hover > .related-product__img,
:focus > .related-product__img {
    transform: scale(1);
    }

.related-product__title {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    text-decoration: inherit;
    }


.related-product-details {
    padding-top: .5em;
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    text-decoration: inherit;
    font-size: .9em;
    text-align: center;

    &__price {
        font-weight: bold;
        }
    }

@media screen and (min-width: 600px)
{
    .related-product-details {
        margin: 0 10px;
        text-align: left;

        &__price {
            text-align: right;
            }
        }
}

@media screen and (min-width: 700px)
{
   .related-product-details {
        border-top: 1px solid $color-gray;

        &::after {
            content: '';
            display: table;
            clear: left;
            }

        & > * {
            display: block;
            float: left;
            }

        &__title {
            width: 70%;
            }

        &__price {
            width: 30%;
            }
        }

    @supports (display: flex)
    {
        .related-product-details {
            display: flex;
            }
    }
}


@media screen and (min-width: 780px)
{
    .related-product {
        font-size: 1em;
        }
}


@media screen and (min-width: 900px)
{
    .related-product-details {
        font-size: 1em;
        }
}
