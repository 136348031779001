.breadcrumbs {
    margin-bottom: 1em;

    &__list {

        }
    }

.breadcrumb-list {
    list-style: none;
    margin: 0;
    padding: 0;

    &::after {
        content: '';
        display: table;
        clear: $left;
        }

    &__item {
        margin: 0;
        padding: 0;
        float: $left;
        }

    &__item + *::before {
        content: '';
        display: block;
        height: .9em;
        float: $left;
        color: inherit;
        border-left: 1px solid; // color gets inherited from font color
        transform: skew(-17deg);
        margin: 0 .5em;
        }
    }

.breadcrumb {

    &:first-child > *::before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 13px;
        margin-#{$right}: .5em;
        background: url('/assets/images/home.svg') 0 0;
        }

    &:first-child:hover > *::before,
    &:first-child:focus > *::before {
        background: url('/assets/images/home.svg') 0 -14px;
        }

    &__inner {

        &:link,
        &:visited {
            color: $color-highlight;
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: underline;
                }
            }

        }
    }
