.grid-video {
    width: 100%;
    border: 0;
    border-radius: 0;
    overflow: hidden;
    padding: 0;

    &:hover::before,
    &:focus::before {
        transform: scale(1.05);
        }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: no-repeat center center / cover;
        background-image: inherit;
        transition: .8s 0s transform;
        }

    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 25%;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(-180deg, rgba($color-gradient-light, 0) 0%, rgba($color-primary, .55) 100%);
        }
    }

.grid-video__title {
    display: block;
    width: 100%;
    padding-bottom: .5em;
    position: absolute;
    bottom: 0;
    z-index: 1;
    text-align: center;
    text-decoration: none;
    color: white;
    font-size: 1.5em;
    font-weight: 400;
    }

.grid-video__triangle {
    width: 0;
    height: 0;
    padding: 15%;
    padding-right: 0;
    overflow: hidden;
    position: absolute;
    top: 36%;
    right: 42%;


    &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        margin-top: -400px;
        margin-left: -650px;
        border-style: solid;
        border-width: 400px 150px 400px 650px;
        border-color: transparent transparent transparent white;
        filter: drop-shadow(0 1px 1px rgba(black, .3));
        transition: .2s 0s filter;
        }

    &:focus,
    &:hover {
        & > .grid-video__triangle::after {
            filter: drop-shadow(0 2px 2px rgba(black, .6));
        }

        & > .grid-video__title {
            text-decoration: underline;
        }
    }
    }
