.checkbox-button {
    height: 22px;
    padding: side-values(0 0 0 30px);
    margin: 0;
    border: 0;
    background-color: transparent;
    font-size: .875em;
    position: relative;
    text-align: $left;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        }

    &::before {
        width: 22px;
        height: 22px;
        border: 1px solid $color-form-border;
        border-radius: 3px;
        #{$left}: 0;
        top: 0;
        }

    &::after {
        width: 22px;
        height: 22px;
        #{$left}: 0;
        top: 0;
        background-position: center center;
        background-repeat: no-repeat;
        }

    &[aria-expanded=false]::after {
        background-image: url('/assets/images/checkmark.svg');
        }
    }
