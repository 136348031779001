.lightbox {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    transition: .4s .2s  background-color;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background-color: rgba($color-primary-dark, 0);

    &:focus {
        outline: dotted white 1px;
        }

    &[hidden] {
        display: none;
        }

    &--visible {
        background-color: rgba($color-primary-dark, .95);
        transition: .4s 0s  background-color;
        }

    &__inner {
        padding: 0 10px 30px;
        opacity: 0;
        transform: translateY(-100%);
        transition: .2s 0s opacity ease-out,
                    .2s 0s transform ease-out;
        }

    &--visible &__inner {
        opacity: 1;
        transform: translateY(0);
        transition: .2s .3s opacity ease-out,
                    .2s .2s transform ease-out;
        }

    &__close {
        margin: 15px 0 15px 15px;
        padding-#{$right}: 1.7em;
        border: 0;
        border-radius: 0;
        position: relative;
        float: $right;
        background-color: transparent;
        color: white;

        &:focus {
            outline: dotted white 1px;
            }

        &:hover {
            text-decoration: underline;
            }

        &::after,
        &::before {
            content: '';
            display: block;
            width: 24px;
            height: 4px;
            border-radius: 2px;
            background-color: white;
            position: absolute;
            #{$right}: 0;
            top: .8ex;
            transform-origin: center center;
            }

        &::before {
            transform: rotate(45deg);
            }
        &::after {
            transform: rotate(-45deg);
            }
        }

    &__content {
        clear: $right;
        }
    }

@media screen and (min-width: 600px)
{
    .lightbox {
        display: flex;
        justify-content: center;
        align-items: center;

        &--hidden {
            display: none;
            }

        &__inner {
            max-height: 100vh;
            padding: 0 30px 30px;
            }

        &__content {
            margin-bottom: 30px;
            }
        }
}


.lightbox-sm {
    max-width: 540px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background-color: white;

    &__img {
        order: 1;
        }

    &__header {
        order: 0;
        padding: 10px;
        }

    &__icon {
        vertical-align: text-bottom;
        margin-#{$right}: .2em;
        }

    &__name {
        font-weight: 700;
        &::before {
            content: '@';
            font-weight: 400;
            }
        }

    &__title {
        order: 2;
        padding: 30px;
        margin: 0;
        }

    &__text {
        order: 2;
        padding: 0 30px 30px 30px;
        font-size: .875em;

        & > :last-child {
            margin: 0;
            }
        }
    }


.lightbox-text {
    max-width: 540px;
    margin: 0 auto;
    background-color: white;

    &__title {
        padding: 30px;
        margin: 0;
        }

    &__text {
        padding: 0 30px 30px 30px;
        font-size: .875em;

        & > :last-child {
            margin: 0;
            }
        }
    }

.lightbox-video {
    margin: 0 auto;
    }
