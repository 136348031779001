
.cart-page__inner {
    max-width: 940px;
    margin: 0 auto;

    &--narrow {
        max-width: 580px;
        }

    &--plain-view {
        max-width: 1020px;
        }
    }

.cart-page__bottom {
    clear: $right;
    &::after {
        content: '';
        display: table;
        clear: both;
        }
    }

.cart-page__previous {
    float: $left;
    }

.cart-page__next {
    float: $right;
    }

.cart {
    border-collapse: collapse;
    width: 100%;
    margin: 60px auto 30px;
    font-weight: 300;
    font-size: .875em;

    & > * > * > * {
        padding: 0;
        }
    }

.cart__header {

    &:not(&--plain-view) > * > :first-child,
    &--plain-view > * > :first-child + * {
        text-align: $left;
        }

    & > * > * {
        border-bottom: 1px solid $color-primary;
        font-weight: inherit;
        font-size: inherit;
        text-transform: uppercase;
        }

    &--plain-view > * > :first-child,
    &--plain-view > * > :last-child {
        border-bottom: transparent;
        }

    &:not(&--plain-view) > * > * ~ *,
    &--plain-view > * > * + * ~ * {
        padding-bottom: 1ex;
        padding-#{$left}: .5em;
        text-align: $right;
        }
    }

.cart-product {

    &--is-unavailable {
        background-color: $color-gray-light;
        color: $color-gray-real-dark;
        }

    & > * {
        font-weight: inherit;
        font-size: inherit;
        }

    &__delete {
        border-bottom-color: transparent;
        }

    &__name {
        font-weight: normal;
        text-align: $left;

        & > :first-child {
            width: 100px;
            border: solid 1px $color-bg;
            }
        }

    &__name--is-unavailable > :first-child {
        opacity: .5;
        }

    &__price--is-unavailable,
    &__total-price--is-unavailable {
        text-decoration: line-through;
        }

    &__delete {
        path {
            transition: .2s fill;
            }

        &:focus path,
        &:hover path {
            fill: $color-primary;
            }
        }
    }

.cart-total {

    width: 100%;
    max-width: 380px;
    float: $right;
    font-weight: 300;
    font-size: .875em;
    }

.cart-total__row {
    padding: 1ex 0;

    & > * {
        padding: .5ex 0;
        }

    &:first-child {
        margin-bottom: 2ex;
        border-bottom: 1px solid $color-primary;
        }

    &:last-child {
        margin-top: 2ex;
        border-top: 1px solid $color-primary;
        }

    &:first-child:last-child {
        border-top: 0;
        }

    & > * {
        float: $left;
        }

    &::after {
        content: '';
        display: table;
        clear: $left;
        }
    }

.cart-total__key {
    text-transform: uppercase;
    width: 200px;
    }

.cart-total__value {
    width: calc(100% - 200px);
    margin: 0;
    text-align: $right;

    &--complementary {
        width: 200px;
        font-size: .642857143em;
        text-align: $left;
        & > * {
            font-size: inherit;
            }
        }
    }

@media screen and (max-width: 639px)
{
    .cart,
    .cart > * ,
    .cart > * > * {
        display: block;
        }

    .cart__header  > * > :first-child {
        display: block;
        }

    .cart__header  > * > * ~ *{
        display: none;
        }

    .cart-product {
        position: relative;
        padding: side-values(15px 35px 15px 0);
        border-bottom: solid 1px $color-brown-gray-light;

        & > * {
            display: inline-block;
            }

        &__name {
            display: block;
            clear: $left;

            &::after {
                content: '';
                display: table;
                clear: $left;
                }

            & > :first-child {
                float: $left;
                margin-#{$right}: 1em;
                }

            & > * + * {
                display: inline-block;
                margin-bottom: 2em;
                max-width: calc(100% - (100px + 1em));
                }
            }

        &__amount {
            position: absolute;
            bottom: 15px;
            #{$left}: calc(100px + 1em);
            }

        &__price {
            display: none;
            }


        &__total-price {
            padding-bottom: 1em;
            padding-left: 1em;
            position: absolute;
            bottom: 15px;
            #{$left}: calc(100px + 1em + 3em + 1em);
            }

        &__delete {
            width: 20px;
            height: 20px;
            position: absolute;
            #{$right}: 0;
            top: 15px;
            text-align: $right;
            }
        }

    .cart--plain-view {



        .cart-product {
            padding: 15px 0;

            &__name > * + * {
                margin: 0;
                height: 70px;
                display: flex;
                flex-direction: column;

                & > * + * {
                    margin: auto 0;
                    }
                }

            &__side {
                height: 100%;
                width: 30px;
                position: absolute;
                top: 0;
                background-color: inherit;

                &:first-child {
                    right: 100%;
                    }

                &:last-child {
                    left: 100%;
                    }
                }

            &__amount {
                border: solid 1px $color-bg;
                }

            &__total-price {
                padding: 0;
                }

            &__total-price,
            &__amount {
                display: flex;
                min-width: 30px;
                height: 30px;
                align-items: center;
                justify-content: center;
                }
            }


        }
}

@media screen and (min-width: 600px)
{
    .cart-page {
        &__inner-wrapper {
            max-width: 800px;
            margin: 0 auto;
            }

        &__form {
            margin-top: 60px;
            clear: both;
            }
        }
}

@media screen and (min-width: 640px)
{
    .cart-product {

        & > * {
            border-bottom: solid 1px $color-brown-gray-light;
            padding: 15px 0;
            }

        &__name ~ * {
            text-align: $right;
            }

        &__name ~ *:not(:last-child) {
            padding-#{$left}: .5em;
            }

        &__amount > * {
            display: inline-block;
            text-align: $left;
            }

        &__delete {
            width: 30px;
            padding-#{$left}: 30px;
            border-bottom: transparent;
            }

        &__name {
            white-space: nowrap;
            padding-#{$right}: 1em;

            & > :last-child {
                max-width: calc(100% - 100px - 0.2em);
                display: inline-flex;
                height: 100px;
                margin-#{$left}: 30px;
                vertical-align: top;
                justify-content: center;
                flex-direction: column;
                white-space: normal;

                & :first-child {
                    margin: auto 0;
                    }
                }
            }
        }

    .cart-total {
        margin-#{$right}: 45px;
        margin-bottom: 60px;
        }

    .cart--plain-view {
        width: calc(100% + 60px);
        margin-left: -30px;
        margin-right: -30px;
        }

    .cart-product__side {
        border-color: transparent;
        width: 30px;
        }
}

@media screen and (min-width: 1080px) and (max-width: 1280px),
       screen and (min-width: 1440px)
{
    .cart {
        width: calc(100% + 45px);
        }

    .cart--plain-view {
        width: calc(100% + 60px);
        margin-right: -30px;
        margin-bottom: 0;
        margin-left: -30px;
        }

    .cart-total {
        margin-right: 0;
        }

    .cart-product__side {
        width: 30px;
        }
}

@media screen and (min-width: 1280px)
{
    @include if-ltr
    {
        .cart-product--is-unavailable .cart-product__side:first-child{
            box-shadow: 30px 0 15px -25px rgba(black, .1) inset;
            }
    }

    @include if-rtl
    {
        .cart-product--is-unavailable .cart-product__side:last-child{
            box-shadow: -30px 0 15px -25px rgba(black, .1) inset;
            }
    }
}
