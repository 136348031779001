.form {

    }

.form__row {
    max-width: 480px;
    margin-bottom: 18px;

    &--spacing {
        margin-top: 30px;
        }

    &--wide {
        max-width: none;
        }

    &::after {
        content: '';
        display: table;
        clear: $right;
        }
    }

.form__label {
    display: block;
    margin-bottom: .5em;
    font-size: .875em;
    font-weight: bold;

        &--required:after{
            content: '*';
            padding-left: 3px;
            font-weight: normal;
            font-family: Arial,Helvetica Neue,Helvetica,sans-serif;
            color: #b41535;
            display: inline-block;
            position: relative;
        }
    }

.form__label-complementary {
    display: block;
    margin-bottom: .5em;
    font-size: .875em;
    }

.form__required {
    vertical-align: middle;
    font-weight: 300;
    font-size: 1.4em;
    }

.form__input {
    display: block;
    width: 100%;
    height: 48px;
    padding: .5em;
    border: 1px solid $color-form-border;
    border-radius: 0;
    background-clip: padding-box;
    font-weight: 300;

    &[disabled] {
        color: $color-brown-gray;
        background-color: $color-gray-light;
        cursor: default;
        }

    &[readonly] {
        height: auto;
        padding: 0;
        border: 0;
        background-color: transparent;
        cursor: default;
        font-size: .875em;

        &:focus {
            box-shadow: none;
            border: 0;
            }
        }

    &:focus {
        outline: 0;
        box-shadow: 0 0 3px 0 $color-form-border;
        border-bottom: 1px solid rgba(black, .23);
        }

    &--text {
        min-height: 48px;
        height: calc(48px * 4);
        font-size: .875em;
        font-weight: 300;
        }

    &--form-error {
        border-color: $color-alert-error-font;

        &:focus {
            box-shadow: 0 0 1px 0 $color-alert-error-font;
            border-bottom: 1px solid $color-alert-error-font-highlight;
            }
        }
    }

.form__error {
        border-bottom: 1px solid #a94442;
        padding: 5px;
        font-size: 12px;
    }

.form__submit {
    height: 42px;
    padding: 0 1.5em;
    float: $right;
    border: 0;
    border-radius: 0;
    background-color: $color-secondary;
    font-size: .875em;
    font-weight: 300;
    letter-spacing: .02em;
    text-transform: uppercase;
    color: white;
    cursor: pointer;

    &--small {
        height: 31px;
        padding: 0 1.3em 0 1em;
        }

    & > * > * {
        position: relative;
        #{$right}: -7px;
        top: 1px;
        }

    @include if-rtl()
    {
        & > * > * {
            transform: rotate(180deg);
            }
    }

    &:hover,
    &:focus {
        text-decoration: underline;
        }

    &:active {
        & > * > * {
            transition: $animation-duration transform;
            transform: translateX(50%);

            @include if-rtl()
            {
                transform: rotate(180deg) translateX(50%);
            }

            }
        }
    }

.form__clear {
    height: 42px;
    padding: 0 1.5em;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    font-size: .875em;
    font-weight: 300;
    letter-spacing: .02em;
    text-transform: uppercase;
    color: $color-highlight;
    cursor: pointer;

    &--small {
        height: 31px;
        padding: 0 1.3em 0 1em;
        }

    &:hover,
    &:focus {
        text-decoration: underline;
        }

    }

.form-container {

    border: 0;
    padding: 0;
    margin: 0 0 18px 0;

    &[aria-hidden=true]{
        display: none;
        }
    &[aria-hidden=false]{
        display: block;
        }

    &__title {
        margin-bottom: 2em;
        margin-top: 1em;
        font-weight: bold;
        }
    }


.form-select {
    height: 42px;
    position: relative;
    overflow: hidden;
    user-select: none;
    border: 1px solid $color-form-border;

    &__img {
        margin: side-values(7px 0 0 .8em);
        }

    &__icon {
        position: absolute;
        #{$right}: 13px;
        top: 13px;
        pointer-events: none;
        cursor: pointer;
        }

    &__inner {
        display: inline-block;
        height: 41px;
        width: calc(100% + 1em);
        margin-#{$right}: -1em;
        padding-#{$right}: 3em;
        padding-#{$left}: .6em;
        -webkit-appearance: none;
        border: 0;
        vertical-align: top;
        background: transparent;
        cursor: pointer;

        &:focus {
            outline: 0;
            text-decoration: underline;
            }

        &::-moz-focus-inner{
            color: transparent;
            text-shadow: 0 0 0 $color-font;
            text-decoration: underline;
            }
        }

    &--has-focus {
        box-shadow: 0 0 3px 0 $color-form-border;
        border-bottom: 1px solid rgba(black, .23);
        }
    }

.form__remember-me {
    padding: .9em 1em .9em 0;
    display: block;
    float: $left;
    font-size: .875em;
    }

.form__forgot-password {
    padding: 0.7em 0;
    font-size: .875em;
    float: $right;
    }

.form__fake-checkbox {
    user-select: none;

    & > * {
        display: inline-block;
        max-width: calc(100% - (16px + .5em));
        vertical-align: top;
        text-transform: uppercase;
        font-size: .875em;
        cursor: pointer;
        }

    :focus + & > *,
    &:hover > * {
        text-decoration: underline;
        }

    &::before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-#{$right}: .5em;
        background-color: $color-gray-dark;
        font-size: .875em;
        cursor: pointer;
        }

    :checked + &::before {
        background-color: $color-highlight;
        }
    }


.form__group {

    }

.form-group {
    border: 0;
    margin: 0;
    padding: 0;

    &__title {
        margin: $grid-gutter-small 0;
        padding: 0;
        font-weight: 700;
        font-size: 1em;
        }
    }

.recaptcha-container{
    margin-top: 20px;
    margin-bottom: 18px;

    .g-recaptcha{
        display: inline-block;
    }
}

@media screen and (min-width: 450px)
{
    .form-group__title {
        margin-bottom: $grid-gutter;
        }
}
