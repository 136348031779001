.submenu-opener {
    width: 40px;
    height: 40px;
    padding: 0;
    border: 0;
    position: relative;
    background: none;
    cursor: pointer;

    &:focus path,
    &:hover path {
        fill: $color-highlight;
        }

    &__icon {
        display: block;
        width: 17px;
        height: 10px;
        position: absolute;
        top: 15px;
        #{$left}: 10px;
        transition: .2s 0s ease-in-out transform;
        transform-origin: center center;

        & > * {
            position: absolute;
            top: 0;
            left: 0;
            }
        }

    &[aria-expanded=false] &__icon {
        transform: scale(1) rotate(0deg);
        }
    &[aria-expanded=true] &__icon {
        transform: scale(1) rotate(-180deg);
        }

    &[aria-expanded=false]:active &__icon {
        transform: scale(.9) rotate(0deg);
        }
    &[aria-expanded=true]:active &__icon {
        transform: scale(.9) rotate(-180deg);
        }
    }
