$grain: url('/assets/images/grain-soft.png');

.recipe-page__top {
  background-color: $color-primary;
  background-image: $grain;
  color: white;
}

.recipe-page-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;

  &__title {
    font-size: 2em;
    margin-bottom: 0;
  }
}

.recipe-page-header-content {
  display: flex;
  flex-direction: column;

  &__text {
    font-size: .875em;
    width: 100%;
    line-height: 21px;
  }
  &__link {
    text-transform: uppercase;
    font-weight: bold;
    padding: .5em 0;
  }
}

.recipe-carousel {

  &__item {
    padding-top: 50%;
    width: 100%;
    background-size: cover;
    background-position: center center;
  }
}

.recipe-content {

  &__header {

  }

  &__section {
    &:nth-child(2n) {
      & .recipe-ingredients {
        background-color: transparent;
        color: $color-primary;

        & .recipe-ingredients-list__item {
          border-color: rgba($color-primary, .25);
        }
      }
    }
  }
}

.recipe-header {
  width: 100%;
  background-color: $color-primary;
  background-image: $grain;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 30px;

  &__title {
    margin: 0;
    padding: 6px 0;
    font-size: 24px;
    color: white;
  }
  &__download {
    margin-bottom: 0;
    background-color: #C7D300;
    font-size: .875em;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.recipe-section {
  display: flex;
  flex-wrap: wrap;

  &__ingredients,
  &__content {
    width: 100%;
    padding: 23px 30px;
  }

  &__content {
    position: relative;
  }

  &:last-of-type {
    .recipe-section__content::after {
      height: 0;
    }
  }
}

.recipe-ingredients {
  background-color: $color-primary;
  background-image: $grain;
  color: white;

  &__title {
    font-size: 1em;
  }
  &__list {
    font-size: .875em;
  }
}

.recipe-ingredients-list {
  padding: 0;

  &__item {
    display: flex;
    justify-content: space-between;
    line-height: 26px;
    border-bottom: 1px solid rgba(white, .2);

    &--total {
      font-weight: bold;
      border: none;
    }
  }
}

.recipe-section-content {

  &__title {
    font-size: 1em;
  }
  &__text {
    font-size: .875em;
  }
}

.recipe-assembly {
  padding: 23px 30px;

  &__title {
    font-size: 1em;
  }
  &__text {
    font-size: .875em;
  }
}

.recipe-related {
  //padding-top: 30px;

  &__products {
    padding: 0;
  }
}

.recipe-related-products {
  display: flex;
  justify-content: center;
  list-style: none;
  flex-wrap: wrap;

  &__item {
    width: 100%;
  }
}

.recipe-related-product {
  padding: 0 30px;
  cursor: pointer;
  transition-duration: .2s;

  &:hover {
    .recipe-related-product-header__link {
      text-decoration: underline;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
  }
  &__image {
    overflow: hidden;
    width: 100%;
    height: 225px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.recipe-related-product-header {
  text-align: center;

  &__link {
    text-decoration: none;
  }
}

@media screen and (min-width: 600px) {
  .recipe-related-products {
    &__item {
      width: calc(100% / 3);
    }
  }

  .recipe-related-product {
    &__image {
      height: 200px;
    }
  }

  .recipe-carousel {
    &__item {
      padding-top: 29%;
    }
  }
}

@media screen and (min-width: 700px) {
  .recipe-page-header-content {
    flex-direction: row;
    justify-content: space-between;

    &__text {
      width: 70%;
    }
    &__link {
      align-self: flex-end;
      padding: .9em 1.5em;
    }
  }

  .recipe-section {
    &__ingredients,
    &__content {
      width: 50%;
    }

    &__content {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        height: 1px;
        background-color: rgba(#C28250, .2);
      }
    }
  }
}

@media screen and (min-width: 800px) {
  .recipe-related-products {
    &__item {
      width: 20%;
    }
  }
  .recipe-related-product {
    &__image {
      height: 150px;
    }
  }
}
