.grid-image {
    width: 100%;
    border: 0;
    border-radius: 0;
    overflow: hidden;
    padding: 0;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: no-repeat center center / cover;
        background-image: inherit;
        transition: .8s 0s transform;
        }

    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 25%;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(-180deg, rgba($color-gradient-light, 0) 0%, rgba($color-primary, .55) 100%);
        }
    }

.grid-item:not(.grid-item--image) .grid-image {
    &:hover::before,
    &:focus::before {
        transform: scale(1.05);
    }
}

.grid-image__title {
    display: block;
    width: 100%;
    padding-bottom: .5em;
    position: absolute;
    bottom: 0;
    z-index: 1;
    text-align: center;
    text-decoration: none;
    color: white;
    font-size: 1.2em;
    text-transform: capitalize;
    }
