.sidebar-wrapper {
    width: 100%;
    height: calc(100% - #{$narrow-header-height});
    max-width: $sidebar-width;

    position: fixed;
    z-index: 2;
    margin-top: $wide-header-height;
    transition: .2s transform;

    &[aria-hidden=true] {
        transform: translateX(if-ltr(-100%, 100%));
        }
    &[aria-hidden=false] {
        transform: translateX(0);
        }

    &--hidden {
        display: none;
        }
    }

.sidebar {
    height: calc(100vh - #{$wide-header-height});
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0 5px 3px 1px rgba(black, .3);

    @supports (color: var(--ios-nav-bar-height))
    {
        @media (orientation: portrait)
        {
            height: calc(100vh - #{$wide-header-height} - var(--js-ios-nav-bar-height));
        }
    }
    }

.sidebar__filler {
    flex-grow: 1;
    min-height: 50px;
    }

.sidebar__menu {

    }

.sidebar-bottom {
    margin-top: -1px; // overlap the border of the element that sits above this one
    border-bottom: solid 1px $color-gray;
    border-top: solid 1px $color-gray;

    &__row {
        display: flex;
        height: 40px;
        padding-#{$left}: 20px;
        align-items: center;
        }

    &__row + &__row {
        border-top: solid 1px $color-gray;
        }

    &__row--big {
        height: auto;
        }
    }


.login-menu-container {

    }

.login-menu-title {
    display: block;
    width: 100%;
    padding: side-values(.675em 1.25em .675em 20px);
    border-radius: 0;
    border: 0;
    border-bottom: solid 1px $color-gray;
    font-size: .875em;
    font-weight: 700;
    text-align: $left;
    text-transform: uppercase;
    color: $color-primary;
    background-color: white;
    position: relative;


    &:hover,
    &:focus {

        .login-menu-title__text {
            text-decoration: underline;
            }
        }

    & :first-child {
        margin-#{$right}: .1em;
        }

    &__icon {
        position: absolute;
        #{$right}: 13px;
        top: 13px;
        transition: .2s 0s ease-in-out transform;
        transform-origin: center center;
        }

    &[aria-expanded=true] {
        background-color: $color-gray;
        }

    //&[aria-expanded=false] &__icon {
    //    transform: scale(1) rotate(0deg);
    //    }
    //&[aria-expanded=true] &__icon {
    //    transform: scale(1) rotate(-180deg);
    //    }
    //
    //&[aria-expanded=false]:active &__icon {
    //    transform: scale(.9) rotate(0deg);
    //    }
    //&[aria-expanded=true]:active &__icon {
    //    transform: scale(.9) rotate(-180deg);
    //    }
    &:active &__icon {
        transform: scale(.9);
        }
    }




@media screen and (min-width: 600px)
{

}

@media screen and (min-width: 1280px)
{
    .sidebar-wrapper {
        width: $sidebar-width;
        position: static;
        margin-top: 0;
        }

    .sidebar {
        height: calc(100vh - #{$narrow-header-height});
        position: sticky;
        top: 105px;
        box-shadow: none;
        }
}


.no-js {
    .sidebar-wrapper {
        width: 100%;
        position: static;
        margin-top: 0;
        }

    .sidebar {
        height: auto;
        display: block;
        box-shadow: none;
        }

    }

.no-animations {
    .sidebar-wrapper {
        transition-duration: 0s !important;
        }
    }
