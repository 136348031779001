@keyframes rotate
{
    to {
        transform: rotate(360deg) scale(1.5);
        }
}

.page-grid {
    background-color: white;
    }

.grid {
    margin: 0;

    &::after {
        content: '';
        display: table;
        clear: left;
        }
    }

.grid__sizer {
    width: 50%;
    }

.grid__item {
    width: 50%;
    padding: 0;
    float: left;
    box-shadow: 0 0 4px -2px rgba(black, .4);
    }

.grid-item__inner {
    padding-top: 100%;
    }

.grid-item__inner {
    position: relative;
    & > * {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        }
    }

.grid-item--video {
    width: 100%;
    }

.grid-item--news {
    width: 100%;
    & > * {
        padding-top: 200%;
        }
    }

.grid-load-button {
    display: block;
    margin: 30px auto;
    padding: .9em;
    border: 0;
    border-radius: 0;
    background-color: $color-highlight;
    color: white;
    font-size: .875em;
    text-transform: uppercase;
    box-shadow: 0 0 3px 0 rgba(black, .2);

    & > * {
        display: inline-block;
        margin-#{$left}: .7em;
        margin-#{$right}: .3em;
        transform: rotate(90deg);
        transition: .2s transform;
        }

    &:hover,
    &:focus {
        text-decoration: underline;
        }

    &:active > * {
        transform: rotate(90deg) translateX(50%);
        }

    &--is-loading > * > * {
        animation: 1s infinite rotate;
        }
    }

@media screen and (max-width: 374px)
{
    .grid-item--news {
        width: 100%;
        & > * {
            padding: 0;
            }
        & > *  > * {
            position: static;
            }
        }
}

@media screen and (min-width: 375px)
{
    .grid-item--news {
        width: 50%;
        & > * {
            padding-top: 200%;
            }
        }
}

@media screen and (max-width: 399px)
{
    .grid-item--quote {
        width: 100%;
        & > * {
            padding: 0;
            }
        & > *  > * {
            position: static;
            }
        }
}

@media screen and (min-width: 400px)
{
    .grid__item,
    .grid__sizer {
        width: 50%;
        }

    .grid-item--quote {
        width: 100%;
        & > * {
            padding-top: 50%;
            }
        }

    .grid-item--video {
        width: 100%;
        }
}

@media screen and (min-width: 600px)
{
    .grid__item,
    .grid__sizer {
        width: calc(100% / 3);
        }

    .grid-item--quote {
        width: calc((100% / 3) * 2);
        }

    .grid-item--video {
        width: calc((100% / 3) * 2);
        }
}

@media screen and (min-width: 901px)
{
    .grid__item,
    .grid__sizer {
        width: 25%;
        }

    .grid-item--quote {
        width: 50%;
        }

    .grid-item--video {
        width: 50%;
        }
}
