.related-products {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 30px;
    }

.related-products__item {
    margin: 0;
    padding: 5px;
    }

@media screen and (min-width: 280px)
{
    .related-products {
        &::after {
            content: '';
            display: table;
            clear: left;
            }
        }

    .related-products__item {
        width: 50%;
        float: left;
        }
}

@media screen and (min-width: 420px)
{
    .related-products__item {
        width: 33.333333333%;
        }
}

@media screen and (min-width: 560px)
{
    .related-products__item {
        width: 25%;
        }
}

@media screen and (min-width: 1280px)
{
    .related-products {
        margin-left: 15px;
        margin-right: 15px;
        }
}
