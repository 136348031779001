.recipe-list-item {
    display: block;
    width: 100%;
    padding: 0;
    border: 0;
    border-radius: 0;
    background: none;
    position: relative;
    overflow: hidden;

    &:focus::before,
    &:hover::before {
        transform: scale(1.05);
        }

    &::before {
        content: '';
        display: block;
        background: no-repeat center center / cover;
        background-image: inherit;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: .5s 0s transform;
        }

    &::after {
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 66.666666667%;
        background-image: linear-gradient(-180deg, rgba($color-gradient-light, 0) 0%, rgba($color-primary, .55) 100%);
        }

    &:link,
    &:visited {
        color: rgb(255, 255, 255);
        }

    &__name {
        display: block;
        width: 100%;
        padding-bottom: .5em;
        padding-#{$left}: .5em;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        text-align: left;
        color: white;
        font-size: 1em;

        & > * > * {
            width: .4em;
            height: auto;
            margin-#{$left}: .3em;
            transition: .2s margin-#{$left};
            }

        :hover > & > * > *  {
            margin-#{$left}: .5em;
            }
        }
    }


@media screen and (min-width: 320px)
{
    .grid-recipe__name {
        font-size: 1.3em;
        }
}

@media screen and (min-width: 420px)
{
    .grid-recipe__name {
        font-size: 1.2em;
        }
}

