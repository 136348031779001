.active-filters {
    margin: 0;
    text-transform: uppercase;
    font-size: .875em;

    &__group {
        display: inline;
        margin-#{$right}: .5em;
        font-weight: bold;

        * + & {
            margin-#{$left}: 1em;
            }

        &::after {
            content: ':';
            }
        }

    &__filter {
        display: inline;
        margin: 0;

        & + &::before {
            content: ', ';
            }
        }
    }
