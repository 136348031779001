/**
* This only works on backgrounds with a solid color
*/
@mixin underline($color, $color-bg: white, $offset: 3px, $thickness: 1px)
{
    $offset2: $offset - $thickness;

    text-shadow: -1px -1px 0 $color-bg,
                  1px -1px 0 $color-bg,
                 -1px  1px 0 $color-bg,
                  1px  1px 0 $color-bg;

    background-image: linear-gradient(to top, $color-bg $offset2, transparent 0px),
                      linear-gradient(to top, $color    $offset,  transparent 0px);
}
