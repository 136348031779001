// styles for HTML coming from the Database

.cms-text {

    ol,
    ul {
        padding-left: 1.5em;
        }

    & > img,
    .img-wrapper {
        margin-bottom: 1.25em;
        box-shadow: 0 0 6px -2px rgba(black, .2);
        width: auto;
        height: auto;
        }

    a {
        color: $color-highlight;
        text-decoration: none;
        @include underline($color-highlight, white, 2px, 2px);

        &:hover,
        &:focus {
            @include underline($color-font-dark, white, 2px, 1px);
            }

        &:focus {
            outline-offset: 2px;
            }
        }

    &--dark-bg a {
        @include underline($color-highlight, $color-primary, 2px, 2px);
        &:hover,
        &:focus {
            @include underline($color-highlight, $color-primary, 2px, 1px);
            }
        }


    hr {
        border: 0;
        border-top: 1px solid $color-font-dark;
        }

    .table-wrapper {
        overflow-x: auto;
        }

    // bootstrap style
    table {
        background-color: transparent;
        border-collapse: collapse;
        border-spacing: 0;
        }

    caption {
        padding-top: .5em;
        padding-bottom: .5em;
        font-weight: 400;
        color: $color-primary;
        text-align: center;
        }

    th {
        text-align: left;
        }

    table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
        }

    thead > tr,
    tfoot > tr,
    tbody > tr {
        & > th,
        & > td {
            padding: .5em;
            line-height: 1.5em;
            vertical-align: top;
            border-top: 1px solid $color-font-dark;
            }
        }

    thead > tr > th {
        vertical-align: bottom;
        border-bottom: 2px solid $color-font-dark;
        }

    caption + thead > tr:first-child > th,
    colgroup + thead > tr:first-child > th,
    thead:first-child > tr:first-child > th,
    caption + thead > tr:first-child > td,
    colgroup + thead > tr:first-child > td,
    thead:first-child > tr:first-child > td {
        border-top: 0;
        }

    tbody + tbody {
        border-top: 2px solid $color-font-dark;
        }
    }

@media screen and (max-width: 400px)
{
    .table-wrapper {
        width: auto;
        margin: 0 -30px;

        caption {
            padding-left: .5em;
            }
        }
}

@media screen and (max-width: 399px)
{
    .img-wrapper {
        text-align: center;
        width: auto;
        margin-left: -30px;
        margin-right: -30px;
        }
}

@media screen and (min-width: 400px)
{
    .cms-text > img,
    .cms-text > p > img {
        border-radius: 2px;
        }
}
