.orderhistory {
    width: 100%;
    font-size: .875em;
    text-align: left;
    text-transform: uppercase;
    border-collapse: collapse;
    }

@media screen and (max-width: 499px)
{
    .orderhistory {
        display: block;

        & > * {
            display: block;
            }

        &__header {
            display: none;
            }
        }
}

@media screen and (min-width: 500px)
{
    .orderhistory {
        &__header {
            & > * > * {
                padding-bottom: 4px;
                border-bottom: 1px solid $color-primary;
                font-weight: 300;
                }
            }
        }
}
