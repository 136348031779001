.icon-link {
    display: block;
    width: 100%;
    border: 0;
    padding: 0;
    font-weight: 700;
    text-transform: uppercase;
    text-align: $left;
    background-color: transparent;
    cursor: pointer;

    &:hover,
    &:focus {
        :last-child {
            text-decoration: underline;
            }
        }

    &:active {
        :last-child {
            color: lighten($color-primary, 10%);
            }
        }

    &:link {
        text-decoration: none;
        }

    &__icon {
        display: inline-block;
        margin-#{$right}: .2em;
        vertical-align: middle;
        }

    &__title {
        font-size: .875em;
        transition: .2s color;
        vertical-align: middle;

        &:hover{
            text-decoration: underline;
            }
        }

    &__counter {
        display: inline-block;
        box-sizing: content-box;
        background-color: $color-icon;
        color: white;
        min-width: 11px;
        height: 11px;
        line-height: 11px;
        border-radius: 50%;
        padding: 3px;
        font-size: 0.5em;
        text-align: center;
        vertical-align: middle;

        &:hover{
            text-decoration: none !important;
            }
        }
    }

.icon-link--no-icon {
    padding-left: 1.6em;
    }
