.text-page {
    padding: 0;
    &__part {

        }
    &__collection {
        background-color: #3a2717;
        color: white;
        padding: 1.25em 1.25em 0 1.25em;
        text-align: center;
        }
    }

.text-page-part {
    display: flex;
    flex-direction: column;

    & > * {
        order: 1;
        }

    &__image {
        order: 0;
        text-align: center;
        & > * {
            box-shadow: 0 0 6px -2px rgba(black, .2);
            border-radius: 2px;
            }
        }

    &__title {
        padding: 0 30px;
        margin: 30px 0;
        font-size: 1.5em;
        font-weight: 700;
        line-height: 1.33333em;
        }

    &__text {
        padding: 0 30px;
        margin-bottom: 30px;
        font-size: .875em;
        line-height: 1.5em;
        }
    }

@media screen and (min-width: 600px)
{
    .text-page-part {
        display: block;

        &::after {
            content: '';
            display: table;
            clear: both;
            }

        &__image {
            width: 50%;
            }
        }

    :nth-child(odd) > .text-page-part__image {
        margin: side-values(0 0 30px 30px);
        float: $right;
        }

    :nth-child(even) > .text-page-part__image {
        margin: side-values(0 30px 30px 0);
        float: $left;
        }
}
@media screen and (min-width: 850px)
{
    .text-page {
        &__collection {
            padding: 1.25em 5em 0 5em;
            }
        }

    .text-page-part {

        &__title {
            max-width: 50%;
            display: inline-block;
            }

        &__text {
            width: 50%;
            }
        }

    :nth-child(odd) > .text-page-part__image,
    :nth-child(even) > .text-page-part__image {
        margin: 35px 0 0 0;
        }

    :nth-child(odd) > .text-page-part__text {
        float: $left;
        }

    :nth-child(even) > .text-page-part__text {
        float: $right;
        }
}


@media screen and (min-width: 1280px)
{
    .text-page {
        max-width: 1080px;
        margin: 0 auto;
        box-shadow: if-ltr(30px, -30px) 0 15px -25px rgba(black, .1) inset;
        }
}
