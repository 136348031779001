.no-longer-available {
    font-weight: 300;
    color: $color-primary;

    &::#{$before} {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-#{$right}: .3em;
        background: url('/assets/images/alert-icon.svg');
        vertical-align: text-top;
        }
    }
