.add-to-cart-btn {
    padding: .58em 1.2em .69em;
    border: 0;
    background-color: $color-secondary;



    &__title {
        margin-#{$right}: .5em;
        text-transform: uppercase;
        color: white;

        :hover > &,
        :focus > & {
            text-decoration: underline;
            }

        }

    &__icon {
        position: relative;
        top: .2em;
        }
    }
