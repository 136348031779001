$color-primary: rgb(58, 39, 23);
$color-primary-dark: rgb(39, 25, 14);
$color-secondary: rgb(160, 168, 21);
$color-highlight: rgb(165, 101, 49);
$color-gradient-light: rgb(108, 79, 51);
$color-highlight-dark: darken($color-highlight, 15%);
$color-highlight-light: rgb(181, 130, 89);

$color-gray: rgb(240, 240, 240);
$color-gray-light: rgb(249, 249, 249);
$color-gray-dark: rgb(216, 216, 216);
$color-gray-real-dark: rgb(155, 155, 155);
$color-brown-gray: rgb(184, 177, 171);
$color-brown-gray-light: rgba(118, 91, 68, .1);

$color-product-info: rgb(198, 191, 183);


$color-font-light: rgb(255, 255, 255);
$color-font-dark: rgb(58, 39, 23);
$color-font: $color-font-dark;
$color-font-gray: rgb(139, 139, 139);
$color-font-placeholder: rgb(216, 212, 209);

$color-form-border: rgb(216, 212, 209);


$color-bg: rgb(249, 245, 242);

$color-alert-info-border: rgb(188, 223, 241);
$color-alert-info-background: rgb(217, 237, 247);
$color-alert-info-font: rgb(49, 112, 143);
$color-alert-info-font-highlight: rgb(36, 82, 105);

$color-alert-warning-border: rgb(250, 242, 204);
$color-alert-warning-background: rgb(252, 248, 227);
$color-alert-warning-font: rgb(138, 109, 59);
$color-alert-warning-font-highlight: rgb(102, 81, 44);

$color-alert-error-border: rgb(235, 204, 204);
$color-alert-error-background: rgb(242, 222, 222);
$color-alert-error-font: rgb(169, 68, 66);
$color-alert-error-font-highlight: rgb(132, 53, 52);

$color-alert-success-border: rgb(208, 233, 198);
$color-alert-success-background: rgb(223, 240, 216);
$color-alert-success-font: rgb(60, 118, 61);
$color-alert-success-font-highlight: rgb(43, 84, 44);

$color-icon: rgb(199, 211, 0);


