.page-jump-links {
    list-style: none;
    padding: 0;
    margin: 0 0 1em 0;

    &__item {
        padding: 0;
        margin: .3em 0 0 0;
        }

    :link,
    :visited {
        color: $color-highlight;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
            }
        }
    }

@media screen and (min-width: 600px)
{
    .page-jump-links__item {
        display: inline-block;
        margin: 0;
        & + * {
            margin-#{$left}: 2em;
            }
        }
}
