.menu {
    margin: 0;
    padding: 0;
    list-style: none;
    border-top: solid 1px $color-gray;
    border-bottom: solid 1px $color-gray;
    text-transform: uppercase;
    font-weight: 700;

    &__item + &__item {
        border-top: solid 1px $color-gray;
        }
    }

.submenu {
    margin: side-values(0 0 0 1.5em);
    padding: 0;
    list-style: none;

    text-transform: uppercase;
    font-weight: 700;

    &[aria-hidden=true]{
        display: none;
        }
    &[aria-hidden=false]{
        display: block;
        }
    }

.menu-item {

    &__link {
        display: block;
        padding: .675em 1.25em;

        &:link,
        &:visited {
            text-decoration: none;
            }

        &--narrow {
            width: calc(100% - 40px);
            float: $left;
            }

        &:hover,
        &:focus {
            color: $color-highlight;
            }
        }

    &__submenu-opener {
        float: $left;
        }

    &__submenu {
        clear: $left;
        background-color: white;
        }
    }

.submenu-item {

    &__link {
        display: block;
        padding: .675em 1.25em;
        font-size: .875em;
        color: $color-highlight;

        &:link,
        &:visited {
            text-decoration: none;
            }

        &:hover,
        &:focus {
            & > * {
                @include underline($color: $color-highlight, $offset: 1px);
                }
            }
        }
    }

.menu-item--is-active {
    background-color: $color-gray;
    }

.submenu-item__link--is-active {
    position: relative;
    &:before{
        content: '';
        display: block;
        position: absolute;
        top: 1.2em;
        #{$left}: .3em;
        border-radius: 50%;
        color: inherit;
        border: .214285714em solid;
        }
    }
