.btn-expand {
    display: block;
    margin: 10px auto 20px;
    border: 0;
    background-color: transparent;

    &--expanded &__icon > * {
        transform: rotate(-180deg);
        }
    }
