.header-wrapper {
    float: $left;
    }

.header {
    height: $wide-header-height;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 2;
    background: white;
    box-shadow: 0 0 3px 1px rgba(black, .15);
    }

.header__logo {

    }

.header-logo {
    display: block;
    height: 0;
    margin: 8px 0 0 0;
    text-align: center;

    &__logo {
        display: inline-block;
        height: 43px;
        width: 136px; // needed for Edge
        }
    }

.header__menu-btn {
    position: absolute;
    top: 20px;
    #{$left}: 20px;
    }

@media screen and (min-width: 600px)
{

}

@media screen and (min-width: 1280px)
{
    .header-wrapper {
        position: relative;
        z-index: 1;
        box-shadow: 0 5px 3px 1px rgba(black, .15);
        }

    .header {
        width: $sidebar-width;
        height: $narrow-header-height;
        box-shadow: none;
        position: static;
        position: sticky;
        }

    .header-logo {
        display: block;
        padding: side-values(14px 12px 5px 12px);
        text-align: $left;
        margin: 0;
        height: 100%;

        &__logo {
            height: auto;
            width: auto;
            max-width: 100%;
            max-height: 100%;
            }
        }

    .header__menu-btn {
        display: none;
        }
}

.no-js {
    .header {
        position: static;
        }
    }
