.grid-quote {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 15px;
    background: $color-primary url('/assets/images/grain-soft.png');
    color: white;
    text-align: center;

    &__title {
        margin: 0;
        position: relative;
        z-index: 1;
        font-size: calc(1em + 18vw);
        font-weight: 400;

        .fonts-loaded & {
            font-family: $font-heading-special;
            font-weight: 400;
            }

        &::before {
            content: '\00a0'; // non breaking space. ensures that the first letter is not cut off
            }
        }

    &__text {
        position: relative;
        z-index: 1;
        font-size: .875em;
        font-weight: 400;
        }

    &__person {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        left: 0;
        z-index: 0;
        background-repeat: no-repeat;
        background-position: bottom $right;
        opacity: .7;
        }
    }

@media screen and (min-width: 370px)
{
    .grid-quote {
        padding: 30px;
        &__title {
            font-size: 4em;
            }
        }
    }
