.detail-order {
    font-weight: 700;

    &:link,
    &:visited {
        text-decoration: none;
        }

    &:hover,
    &:focus {
        text-decoration: underline;
        }

    &__icon {
        margin-left: 10px;
        vertical-align: middle;

        & * {
            fill: $color-primary;
            }
        }
    }
