.filter-button {
    display: inline-block;
    height: 22px;
    padding: side-values(0 27px 0 7px);
    margin: 0;
    position: relative;
    z-index: 4;
    border: 0;
    border-radius: 3px;
    background-color: transparent;
    font-size: 1em;
    color: $color-font;
    cursor: pointer;
    text-align: $left;
    text-transform: uppercase;
    transition: font-weight .2s;

    -webkit-tap-highlight-color: rgba(black, 0);

    &::after {
        content: '';
        display: block;
        width: 20px;
        height: 22px;
        position: absolute;
        #{$right}: 0;
        top: 0;
        background: no-repeat url('/assets/images/filter-icon.svg');
        }
    }

.filter-button:focus {
    outline: dotted 1px;
    outline-offset: 2px;
    }

.filter-button:active {

    }

.filter-button:hover,
.filter-button:focus {
    font-weight: 400;
    }

.filter-button[aria-expanded="true"] {
    font-weight: 700;
    }

@media screen and (min-width: 320px)
{
    .filter-button {

        }
    }

