.download-btn {
    display: inline-block;
    display: inline-flex;
    height: 40px;
    padding: .8em;
    margin-bottom: 1em;
    align-items: center;
    justify-content: space-between;
    background-color: $color-highlight;

    & > * {
        margin-#{$left}: 1.2em;
        }

    &:link,
    &:visited {
        text-decoration: none;
        color: white;
        }

    &:hover,
    &:focus {
        text-decoration: underline;
        }
    }

.download-btn--cta {
    background-color: $color-secondary;
    }
