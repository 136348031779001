.grid-sm {
    display: block;
    width: 100%;
    padding: 0;
    border: 0;
    border-radius: 0;
    background: none;
    position: relative;
    overflow: hidden;
    color: white;
    font-size: .7em;

    &:focus::before,
    &:hover::before {
        transform: scale(1.05);
        }

    &::before {
        content: '';
        display: block;
        background: no-repeat center center / cover;
        background-image: inherit;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: .5s 0s transform;
        }

    &::after {
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 66.666666667%;
        background-image: linear-gradient(-180deg, rgba($color-gradient-light, 0) 0%, rgba($color-primary, .55) 100%);
        }

    &--linkedin::after {
        background-position: 0 0;
    }
    &--instagram::after {
        background-position: -12px 0;
    }
    &--facebook::after {
        background-position: -24px 0;
    }
    &--pinterest::after {
        background-position: -36px 0;
    }
    }

.grid-sm__name {
    display: block;
    width: 100%;
    padding-bottom: .8em;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    text-align: right;

    &::before {
        content: '@';
        }

    &::after {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        margin: side-values(0 1em 0 .4em);
        vertical-align: middle;
        background: url('/assets/images/inspiration/detail/social-icons-inspiration.svg') -12px 0;
        }
    }

@media screen and (min-width: 320px)
{
    .grid-sm {
        font-size: 1em;
        }
}
