.body {
    &--has-scroll-lock {
        width: 100%;
        position: fixed;
        overflow: hidden;

        & > .body__inner {
            width: 100%;
            position: fixed;
            left: 0;
            }
        }
    }

.body__inner {
    max-width: 1440px;
    margin: 0 auto;
    }

.wrapper {
    min-height: 100vh;
    padding-top: $wide-header-height;
    }

.wrapper__inner {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - #{$wide-header-height});
    }

.wrapper {
    transition: none;
    //overflow: hidden;

    &.is-animating {
        transition: transform .5s 0s ease-out;
        }
    }

.wrapper__inner > :first-child {
    flex-grow: 1;
    }

.has-overlay {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(black, 0);
        transition: $animation-duration background-color;
        }

    &--colored::after {
        background-color: rgba(black, .3);
        }
    }

.container {
    max-width: $container-max-width;
    margin: 0 auto;
    padding: 0 $grid-gutter-half;
    @extend .cf;

    &--no-gutters {
        padding: 0;
        }
    }

@media screen and (min-width: 600px)
{

}

@media screen and (min-width: 1280px)
{
    .body__inner {
        display: flex;
        }

    .wrapper {
        padding: 0;
        float: $left;
        width: calc(100% - #{$sidebar-width});
        }

    .wrapper__inner {
        min-height: 100vh;
        }
}

@media screen and (min-width: 1440px)
{
    .body--has-scroll-lock > .body__inner {
        left: calc(50% - 720px);
        }
}


.no-js {
    .wrapper {
        width: 100%;
        padding: 0;
        float: none;
        }

    .body__inner {
        display: block;
        }
    }
