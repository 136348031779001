$footer-font-size: .875em;
$footer-heading-font-size: 1.5em;

.footer {
    padding: 20px;
    background: $color-primary-dark url('/assets/images/grain-soft.png');
    color: white;

    :link,
    :visited {
        color: inherit;
        }
    }

//
//.footer__block {
//    padding-bottom: 20px;
//    }
//
//.footer__block + .footer__block {
//    padding-top: 20px;
//    border-top: 2px solid rgba(255, 255, 255, .1);
//    }
//
//.footer-block__title {
//    font-size: 1em;
//    }

.footer__bottom {
    padding-top: 5px;
    //padding-top: 20px;
    //border-top: 2px solid rgba(255, 255, 255, .1);
    clear: $left;
    font-size: $footer-font-size;
    line-height: 1.5em;
    }

.footer-contact {
    margin: 0;
    font-size: $footer-font-size;
    line-height: 1.5em;

    &__item {
        margin: 0;

        &:first-of-type {
            margin-bottom: .7em;
            }
        }
    }
//
//.form-newsletter {
//    display: flex;
//    max-width: 480px;
//    height: 40px;
//
//    &__input {
//        height: 100%;
//        width: calc(100% - 40px);
//        flex-grow: 1;
//        }
//
//    &__submit {
//        height: 100%;
//        min-width: 40px;
//        border: 0;
//        border-radius: 0;
//        background-color: $color-secondary;
//        font-size: $footer-font-size;
//        font-weight: 300;
//        letter-spacing: .02em;
//        text-transform: uppercase;
//        color: inherit;
//        cursor: pointer;
//
//        & > :first-child {
//            display: none;
//            }
//
//        @include if-rtl()
//        {
//            & > :last-child {
//                transform: rotate(180deg);
//                }
//        }
//
//        &:hover,
//        &:focus {
//            box-shadow: if-ltr(5px, -5px) 0 4px 0 rgba(black, .5);
//            }
//
//        &:active {
//            & > * {
//                transition: $animation-duration transform;
//                transform: translateX(50%);
//
//                @include if-rtl()
//                {
//                    transform: rotate(180deg) translateX(50%);
//                }
//
//                }
//            }
//        }
//    }

.footer-links {
    margin: 0;
    padding: 0;
    list-style: none;

    &__item {

        & :link,
        & :visited {
            text-decoration: none;
            }

        & > :hover,
        & > :focus {
            text-decoration: underline;
            }
        }
    }

.copyright-dobla {
    display: inline-block;
    margin-bottom: 1em;
    font-size: 1em;
    font-weight: 700;
    }

.created-by-reyez {
    display: block;
    margin-top: 1em;
    text-align: center;

    &:link,
    &:visited {
        text-decoration: none;
        }

    &:hover,
    &:focus {
        text-decoration: underline;
        }
    }

@media screen and (min-width: 520px)
{
    .copyright-dobla {
        display: inline;
        margin-bottom: 0;
        font-size: inherit;
        font-weight: 400;
        }

    //.footer__block {
    //    float: $left;
    //
    //    &:first-child {
    //        width: 37.5%;
    //        }
    //
    //    & + & {
    //        width: 62.5%;
    //        padding-top: 0;
    //        border-top: none;
    //        }
    //    }

    .footer-links {
        display: inline-block;

        &__item {
            display: inline-block;

            &::before {
                content: '';
                display: inline-block;
                width: .142857143em;
                height: .928571429em;
                margin: 0 .7em;
                position: relative;
                top: .1em;
                background-color: $color-highlight-light;
                }
            }
        }

    }

@media screen and (min-width: 600px)
{
    .footer {
        padding: 30px;
        }

    //.footer__block {
    //    padding-bottom: 30px;
    //    }

    //.footer__bottom {
    //    padding-top: 30px;
    //    }

    //.footer-block__title {
    //    font-size: $footer-heading-font-size;
    //    }
}


@media screen and (min-width: 768px)
{
    .created-by-reyez {
        float: $right;
        margin: 0;
        }

    //.form-newsletter__input {
    //    width: auto;
    //    }
    //
    //.form-newsletter__submit > :first-child {
    //    display: inline;
    //    }
}
