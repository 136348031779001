.search-submit-btn {
    width: 48px;
    height: 43px;
    padding: 0;
    border: 0;
    border-radius: 0;
    position: relative;
    background-color: $color-gray;
    cursor: pointer;

    &__icon {
        display: block;
        width: 18px;
        height: 18px;
        position: absolute;
        #{$left}: 17px;
        top: 14px;
        transition: .2s 0s ease-in-out transform;

        & > * {
            position: absolute;
            top: 0;
            left: 0;
            }
        }
    }

.search-submit-btn:hover,
.search-submit-btn:focus {
    .search-submit-btn__icon {
        transform: scale(1.15);
        }
    }

.search-submit-btn:active {
    .search-submit-btn__icon {
        transform: scale(1.05);
        }
    }
