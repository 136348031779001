.pagination {
    display: block;
    margin: 0 15px 10px;
    text-align: center;

    &__item {
        display: inline-block;
        color: $color-highlight;
        }

    &__item,
    &__prev,
    &__next {
        min-height: 35px;
        min-width: 35px;
        padding: 1.3ex .5em 0;
        line-height: 1em;
        user-select: none;
        font-weight: 700;

        &,
        &:link,
        &:visited {
            text-decoration: none;
            }

        &:focus,
        &:hover {
            text-decoration: underline;
            }
        }

    &__item--dots {
        padding-left: 0;
        padding-right: 0;
        min-width: 17.5px;
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            }
        }

    &__item--current {
        cursor: default;
        background-color: $color-primary-dark;

        &,
        &:link,
        &:visited {
            color: white;
            }

        &:hover {
            text-decoration: none;
            }
        }

    &__prev,
    &__next {
        display: block;
        background-color: $color-highlight;
        font-size: .875em;

        &,
        &:link,
        &:visited {
            color: white;
            }

        &:focus,
        &:hover {
            text-decoration: underline;
            }

        &--disabled {
            background-color: $color-gray-dark;

            &:focus,
            &:hover {
                text-decoration: none;
                }
            }
        }

    &__prev::before,
    &__next::after {
        content: '';
        display: inline-block;
        width: 6px;
        height: 10px;
        background: url('/assets/images/next.svg');
        }

@if $dir == rtl
{
    &__next::after {
        transform: rotate(-180deg);
        }
}
@else
{
    &__prev::before {
        transform: rotate(-180deg);
        }
}

    &__prev {
        float: $left;
        }

    &__next {
        float: $right;
        }

    &__numbers {
        display: inline-block;
        }
    }


.pagination-numbers {
    margin: 0;
    padding: 0;
    font-weight: 400;
    text-align: $right;
    font-size: .875em;

    &__number {
        display: inline-block;
        margin-bottom: 5px;
        text-align: center;
        }
    }

@media screen and (max-width: 599px)
{
    .pagination {
        padding: 0 40px;
        position: relative;
        }

    .pagination__prev,
    .pagination__next {
        position: absolute;
        top: 0;

        & > *,
        & > * {
            position: absolute;
            left: -10000px;
            }
        }

    .pagination__prev {
        #{$left}: 0;
        }

    .pagination__next {
        #{$right}: 0;
        }
}

@media screen and (min-width: 600px)
{
    .pagination {
        margin: 0 30px 25px;

        &__prev::before {
            margin: side-values(0 .6em 0 .3em);
            }

        &__next::after {
            margin: side-values(0 .3em 0 .6em);
            }
        }
}

