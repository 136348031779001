.login-menu {
    width: 100%;
    margin: 0;
    padding: side-values(0 0 0 20px);
    border-bottom: solid 1px $color-gray;
    list-style: none;
    background-color: $color-gray-light;
    color: $color-font-gray;

    &__item {

        }
    }

.login-menu[aria-hidden=true] {
    display: none;
    }

.login-menu[aria-hidden=false] {
    display: block;
    }

.login-menu-item {

    &__link {
        display: block;
        padding: side-values(.675em 1.25em .675em 1.55em);
        text-transform: uppercase;
        font-size: .875em;
        font-weight: 400;

        &:link,
        &:visited {
            color: inherit;
            text-decoration: none;
            }

        &:hover,
        &:focus {
            text-decoration: underline;
            }
        }
    }
