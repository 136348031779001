.overview-page {
    padding: 0;
    max-width: none;

    &__text {
        padding: 1em;
        max-width: calc(500px - 2em);
        margin: 30px auto;
        text-align: center;
        font-size: .875em;
        & > :last-child {
            margin: 0;
            }
        }
    }

.page-header {
    position: relative;
    margin: 0;

    &__inner {
        display: block;
        padding-top: 75%; // aspect ratio of the image
        background: no-repeat center center / cover;

        &--large {
            display: none;
            }
        }

    & > :last-child {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-shadow: rgba(black, .3) 1px 1px; // improve legibility
        color: white;
        text-align: center;
        }

    &--with-text > :last-child {
        flex-direction: column;
        }

    &__text {
        margin: 0;
        padding: 0 .5em;
        text-align: center;
        font-size: .875em;
        }

    }

.showcase-title {
    margin: .6em 0 0 0;
    padding: 1em;
    font-size: 2em;

    &--featured {
        margin-top: 0;
        background-color: $color-primary;
        color: white;
        }
    }

.showcase {
    list-style: none;
    margin: 0;
    padding: 0;

    &:after {
        content: '';
        display: table;
        clear: left;
        width: 100%; // fix for flexbox bug in safari.
        }
    }

.showcase__item {
    width: 50%;
    max-width: 640px;
    max-height: 320px;
    float: left;
    overflow: hidden;
    position: relative;

    &::before {
        content: '';
        background: no-repeat center center / cover;
        background-image: inherit;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: .8s 0s transform;
        }

    & > * {
        padding-top: 100%;
        }

    &--text {
        &::before {
            display: none;
            }
        & > * {
            padding: 0;
            }
        }
    }

.showcase--square .showcase__item {
    max-width: none;
    max-height: none;
    }

.showcase--featured .showcase__item {
    width: 50%;
    max-height: none;

    &:first-child {
        width: 100%;
        }

    @include exactly(4)
    {
        &:first-child + * {
            width: 100%;
            & > * {
                padding-top: 50%;
                }
            }
    }
    }

.showcase--home .showcase__item {
    width: 50%;
    max-height: none;

    &:first-child {
        width: 100%;
        }

    &:first-child + *,
    &:nth-child(6) {
        width: 100%;
        }

    &:nth-child(6) > * {
        padding-top: 50%;
        }
    }

.showcase--home-inspired .showcase__item {
    width: 50%;
    max-height: none;

    &:first-child {
        width: 100%;
        }
    }

.showcase--quartet .showcase__item {
    width: 50%;
    }


.showcase-item {

    &--clickable {
        cursor: pointer;

        &:hover {
            :link,
            :visited {
                text-decoration: underline;
                }

            &::before {
                transform: scale(1.05);
                }
            }
        }

    &__inner::before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 50%;
        background-image: linear-gradient(-180deg, rgba($color-gradient-light, 0) 0%,
                                                   rgba($color-primary, .55)      100%);
        }

    &__link {
        position: absolute;
        bottom: 1em;
        width: 100%;
        text-align: center;

        &:link,
        &:visited {
            text-decoration: none;
            color: white;
            }
        }
    }

.showcase-text-item {
    &__content {
        position: relative;
        }
    }

.showcase-text-item-content {
    &__inner {
        padding: 30px;
        background: $color-primary url('/assets/images/grain-soft.png');
        color: white;
        }

    &__text {
        font-size: .875em;
        }

    &__link {
        font-size: .875em;
        }
    }

@media screen and (max-width: 599px)
{
    .page-header__text {
        position: absolute;
        left: -100000px;
        }
}

@media screen and (max-width: 639px)
{
    .showcase--home {
        display: flex;
        flex-wrap: wrap;

        & > :nth-child(2) {
            order: -1;
            }
        & > :nth-child(5) {
            order: 6;
            }
        & > :nth-child(7) {
            order: 7;
            }
        }

    .showcase--standard .showcase__item {
        @include exactly(3 5 7 9 11)
        {
            &:nth-child(3) {
                width: 100%;
                & > * {
                    padding-top: 50%;
                    }
                }
            }
        }

    .showcase--home-inspired .showcase__item {
        @include exactly(4 6 8 10)
        {
            &:nth-child(2) {
                width: 100%;
                & > * {
                    padding-top: 50%;
                    }
                }
            }
        }
}


@media screen and (min-width: 600px)
{
    .page-header {
        &__inner {
            padding-top: 29%; // aspect ratio of the image
            &--small {
                display: none;
                }
            &--large {
                display: block;
                }
            }
        }

    .showcase-title {
        margin: 1em 0 0 0;
        &--featured {
            margin-top: 0;
            }
        }

    .overview-page__text {
        margin: 60px auto;
        }
}

@media screen and (min-width: 600px) and (max-width: 1279px)
{
    .fonts-loaded .page-header__title {
        font-size: 8em;
        font-size: calc(1em + 17vw);
        }
}

@media screen and (min-width: 640px) and (max-width: 700px)
{
    .showcase-text-item-content__title {
        margin-bottom: .5em;
        }

    .showcase-text-item-content__text > :last-child{
        margin-bottom: .5em;
        }
}

@media screen and (min-width: 640px) and (max-width: 800px)
{
    .showcase--home {
        font-size: .9em;
        }
}

@media screen and (min-width: 640px)
{
    .showcase--home .showcase__item {
        width: 25%;

        &:first-child,
        &:first-child + *,
        &:nth-child(6) {
            width: 50%;
            }

        &:first-child + * > * {
            padding-top: 50%;
            }
        }

    .showcase__item {
        width: calc(100% / 3);
        }

    .showcase--featured .showcase__item {
        width: 25%;

        &:first-child {
            width: 50%;
            }

        @include exactly(3)
        {
            &:first-child ~ * {
                width: 50%;
                & > * {
                    padding-top: 50%;
                    }
                }
        }

        @include exactly(4)
        {
            &:first-child + * {
                width: 50%;
                & > * {
                    padding-top: 50%;
                    }
                }
        }
        }

    .showcase--standard .showcase__item {
        width: calc(100% / 3);

        @include exactly(2 4 7 10)
        {
            &:first-child,
            &:nth-child(2),
            &:last-child,
            &:nth-last-child(2){
                width: 50%;
                & > * {
                    padding-top: calc((100% / 3)*2);
                    }
                }
        }

        @include exactly(5 8 11)
        {
            &:last-child,
            &:nth-last-child(2){
                width: 50%;
                & > * {
                    padding-top: calc((100% / 3)*2);
                    }
                }
        }
        }

    .showcase--home-inspired .showcase__item {
        width: calc(100% / 3);

        &:first-child > * {
            padding-top: calc((100% / 3) * 2);
            }

        @include exactly(5 7 8 9 11)
        {
            &:first-child,
            &:nth-child(2) {
                width: 50%;
                & > * {
                    padding-top: calc((100% / 3) * 2);
                    }
                }
        }

        @include exactly(4 6)
        {
            width: 50%;
            & > * {
                padding-top: 60%;
                }
        }

        @include exactly(7)
        {
            &:nth-last-child(3) ~ * {
                width: 50%;
                & > * {
                    padding-top: calc((100% / 3) * 2);
                    }
                }
        }
        @include exactly(9)
        {
            &:nth-last-child(5) ~ * {
                width: 50%;
                & > * {
                    padding-top: calc((100% / 3) * 2);
                    }
                }
        }
        }

    .showcase--quartet .showcase__item {
        width: 50%;
        & > * {
            padding-top: 50%;
            }
        }

    .showcase-text-item-content {
        &__inner {
            display: flex;
            padding: 30px;
            flex-direction: column;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            text-align: center;
            }

        }
}
