.grid-news {
    display: flex;
    padding-top: calc(100% + 5px);
    padding-bottom: 5px;
    flex-direction: column;
    //justify-content: space-evenly;
    justify-content: space-around;
    text-align: center;
    background: $color-gray-light url('/assets/images/grain.png');
    overflow: hidden;

    &--inverted {
        background: $color-primary url('/assets/images/grain-soft.png');
        color: white;
        }

    &__img {
        width: 100%;
        position: absolute;
        top: 0;
        box-shadow: 0 4px 6px -4px rgba(black, .4);
        }

    &__title {
        padding: 0 30px;
        margin: 0;
        }

    &__text {
        max-height: 51%;
        padding: 0 30px;
        position: relative;
        overflow: hidden;
        font-size: .875em;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 30px;
            right: 30px;
            height: 1.5ex;
            background-image: linear-gradient(180deg, rgba($color-gray-light, .0), rgba($color-gray-light, 1));
            }
        }

    &--inverted &__text::after {
        background-image: linear-gradient(180deg, rgba($color-primary, .0), rgba($color-primary, 1));
        }

    &__link {
        display: inline-block;
        height: 22px;
        & > * {
            height: 100%;
            }
        }
    }

@media screen and (max-width: 369px)
{
    .grid-news {
        padding-top: calc(100% + 15px);
        padding-bottom: 15px;

        &__title {
            margin-bottom: 15px;
            }
        }
}

@media screen and (max-width: 479px)
{
    .grid-news {
        &__title {
            padding: 0 15px;
            }
        }
}


@media screen and (min-width: 370px) and (max-width: 750px),
       screen and (min-width: 900px) and (max-width: 1050px)
{
    .grid-news {
        font-size: .9em;

        &__title {
            font-size: 1.2em;
            }
        }
}
