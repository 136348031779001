.complaintform {

    }

.complaintform__lotnumber-info {
    background: $color-primary url('/assets/images/grain-soft.png');
    color: white;
    }

.complaintform-lotnumer-images {

    &__image {
        max-height: 250px;
        margin-bottom: 20px;
        }
    }

@media screen and (min-width: 700px)
{
    .complaintform__lotnumer-images {
        position: sticky;
        top: $wide-header-height + 20px;
        //max-height: calc(100vh - (#{$wide-header-height} + 20px));
        //overflow-x: scroll;
        }
}

@media screen and (min-width: 1280px)
{
    .complaintform__lotnumer-images {
        top: 20px;
        }
}
