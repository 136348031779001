.file-input {
    display: block;
    text-align: $right;
    }

.file-input__real-input {
    @extend .is-visually-hidden;

    &--has-focus + .file-input__faux-input {
        background-color: $color-highlight-dark;
        }
    }

.file-input__faux-input {
    display: inline-flex;
    min-height: 40px;
    min-width: 220px;
    max-width: 300px;
    padding: .5em 1em;
    align-items: center;
    justify-content: center;
    line-height: 1.2em;
    font-size: .75em;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: $color-highlight;
    transition: .2s 0s background-color;
    text-align: $left;

    &:hover {
        background-color: $color-highlight-dark;
        }

    & > *:first-child {
        fill: white;
        margin-#{$right}: 1em;
        }

    & > *:last-child {
        margin-top: .2em;
        }
    }

